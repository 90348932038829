<template>
  <router-view />
</template>

<script>
import { provide } from "vue";
import store from "@/store";

// Property
// this will return symatric diffrence of two arrays [1,2,3] and [2,3,5] return [1,5]
Array.prototype.symDifference = function (arr2) {
  return this.filter((x) => !arr2.includes(x));
};

Array.prototype.contains = function (string) {
  this.forEach((element) => {
    console.log("element", element);
    console.log("string", string);
    console.log("element.id == string", element.id == string);
    if (element.id == string) return true;
  });
  return false;
};
Array.prototype.pushIfNotExist = function (object) {
  if (!this.contains(object.id)) {
    this.push(object);
  }
};

Array.range = function (n) {
  // Array.range(5) --> [0,1,2,3,4]
  return Array.apply(null, Array(n)).map((x, i) => i);
};

Object.defineProperty(Array.prototype, "chunk", {
  value: function (n) {
    // ACTUAL CODE FOR CHUNKING ARRAY:
    return Array.range(Math.ceil(this.length / n)).map((x, i) =>
      this.slice(i * n, i * n + n)
    );
  },
});
// export
import { ref } from "vue";
export default {
  setup() {
    provide("store", store);

    const unSync = ref([]);
    store.login.auth.onAuthStateChanged(function (user) {
      if (user && !user.isAnonymous)
        try {
          unSync.value.push(
            store.user.sync(() => {
              const userData = store.user.byUsername(user.displayName)[0]
              store.table.loggedUser = userData
              unSync.value.push(
                store.cases.sync(() => {
                  unSync.value.push(store.tags.sync());
                  unSync.value.push(store.tasks.sync());
                  unSync.value.push(store.client.sync());
                  unSync.value.push(store.targets.sync());
                  unSync.value.push(store.reports.sync());
                  unSync.value.push(store.timesheet.sync(userData));
                  unSync.value.push(store.expenses.sync(userData));
                  unSync.value.push(store.advances.sync(userData));
                })
              );
            })
          );
        } catch (e) {
          console.log(e);
        }
    });

    window.onbeforeunload = function (event) {
      unSync.value.forEach((element) => {
        element();
      });
      for (let index = 0; index < unSync.value.length; index++) {
        unSync.value.pop();
      }
      event.returnValue = true;
    };
  },
};
</script>

<style>
body {
  margin: 0px;
  padding: 0px;
}
</style>
