<template>
  <Dialog
    :header="title"
    v-model:visible="visible"
    :style="{ width: '95vw' }"
    :maximizable="true"
    :modal="true"
    :closable="false"
  >
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="tagId">Tag</label>
        <Dropdown
          :filter="true"
          id="tagId"
          inputId="tagId"
          v-model="formData.tagId"
          :options="tags"
          optionLabel="name"
          optionValue="id"
          placeholder="Select tag"
        />
        <small class="p-error" v-if="formData.tagId.length == 0">
          Tag is required.
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="caseId">Case</label>
        <Dropdown
          :filter="true"
          id="caseId"
          inputId="caseId"
          v-model="formData.caseId"
          :options="cases"
          optionLabel="name"
          optionValue="id"
          placeholder="Select case"
        />
        <small class="p-error" v-if="formData.caseId.length == 0">
          Case is required.
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="rank">Priority (1-10)</label>

        <InputNumber
          id="rank"
          v-model="formData.rank"
          :min="0"
          :max="10"
          placeholder="Priority (1-10)"
        />
        <small class="p-error" v-if="formData.rank.length == 0">
          Priority is required.
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="startTime">Start Time</label>
        <Calendar
          id="startTime"
          :monthNavigator="true"
          :yearNavigator="true"
          :showTime="true"
          hourFormat="12"
          :yearRange="yearRange"
          v-model="formData.startTime"
          placeholder="MM/DD/YYYY hh:mm a"
        />
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="status">Status</label>
        <Dropdown
          id="status"
          inputId="status"
          v-model="formData.status"
          :options="status"
          optionLabel="name"
          optionValue="name"
          placeholder="Select status"
        />
        <small class="p-error" v-if="formData.status.length == 0">
          Status is required.
        </small>
      </div>
    </div>

    <div v-if="src.update" class="p-fluid p-formgrid p-grid">
      <div class="p-col-12 p-md-4">
        <label>Created :</label>
        <small class="p-text-normal p-m-2">
          {{ store.date.toDateTime(formData.time) }}
        </small>
      </div>
      <div class="p-col-12 p-md-4">
        <label>Last Updated :</label>
        <small class="p-text-normal p-m-2">
          {{ store.date.toDateTime(formData.updated) }}
        </small>
      </div>
    </div>

    <DataTable
      showGridlines
      editMode="row"
      class="p-mt-2"
      dataKey="details"
      responsiveLayout="scroll"
      @rowReorder="onRowReorder"
      :value="formData.subtask"
      v-show="formData.subtask.length > 0"
    >
      <Column
        :rowReorder="true"
        headerStyle="width: 3rem"
        :reorderableColumn="false"
      />
      <Column header="Sn." headerStyle="width: 3rem" :reorderableColumn="false">
        <template #body="slotProps">
          <h4 class="p-m-0">{{ slotProps.index + 1 }}</h4>
        </template>
      </Column>

      <Column field="agent" header="Agent Name" style="width: 25%">
        <template #editor="slotProps">
          <Dropdown
            :filter="true"
            v-model="slotProps.data['agent']"
            :options="users"
            style="width: 100%"
            optionLabel="name"
            optionValue="username"
            placeholder="Select Agent"
          >
            <template #option="slotProps">
              <span>{{ slotProps.option.name }}</span>
            </template>
          </Dropdown>
        </template>
        <template #body="slotProps">
          {{ slotProps.data.agent }}
        </template>
      </Column>

      <Column field="details" header="Subtask Details Tag" style="width: 40%">
        <template #editor="slotProps">
          <Dropdown
            :filter="true"
            v-model="slotProps.data['details']"
            :options="subTags"
            optionLabel="name"
            optionValue="id"
            style="width: 100%"
            placeholder="Select Details Tag"
          >
            <template #option="slotProps">
              <span>{{ slotProps.option.name }}</span>
            </template>
          </Dropdown>
        </template>
        <template #body="slotProps">
          {{ slotProps.data.details }}
        </template>
      </Column>

      <Column field="endTime" header="Finish Time" style="width: 20%">
        <template #editor="slotProps">
          <Calendar
            :monthNavigator="true"
            :yearNavigator="true"
            :showTime="true"
            hourFormat="12"
            :yearRange="yearRange"
            :v-model="slotProps.data['endTime']"
            placeholder="MM/DD/YYYY hh:mm a"
          >
          </Calendar>
        </template>
        <template #body="slotProps">
          {{ store.date.toDateTime(slotProps.data.endTime) }}
        </template>
      </Column>

      <Column field="status" header="Status" style="max-width: 100px">
        <template #editor="slotProps">
          <Dropdown
            v-model="slotProps.data['status']"
            :options="status"
            optionLabel="name"
            optionValue="name"
            style="width: 100%"
            placeholder="Select a Status"
          >
            <template #option="slotProps">
              <span>{{ slotProps.option.name }}</span>
            </template>
          </Dropdown>
        </template>
        <template #body="slotProps">
          {{ slotProps.data.status }}
        </template>
      </Column>
      <Column header="Actions" :exportable="false" style="max-width: 100px">
        <template #body="slotProps">
          <div class="p-d-flex">
            <Button
              icon="pi pi-pencil"
              class="p-button-sm p-button-raised p-button-rounded p-mr-2"
              @click="editSubtask(slotProps)"
            />

            <Button
              icon="pi pi-trash"
              class="
                p-button-sm p-button-raised p-button-rounded p-button-danger
              "
              @click="deleteSubtask(slotProps.data)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <template #footer>
      <Button
        label="Add Subtask"
        icon="pi pi-plus"
        @click="createSubtask"
        class="p-button"
      />

      <Button
        v-if="src.create"
        label="Create"
        icon="pi pi-check"
        class="p-button-success"
        @click="create"
      />

      <Button
        v-if="src.update"
        label="Update"
        icon="pi pi-pencil"
        class="p-button-warning"
        @click="update"
      />

      <Button
        v-if="src.update"
        label="Delete"
        icon="pi pi-trash"
        class="p-button-danger"
        @click="dalete"
      />

      <Button
        label="Close"
        icon="pi pi-times"
        @click="close"
        class="p-button-text"
        autofocus
      />
    </template>
  </Dialog>
  <Dialog
    header="Create Subtask"
    v-model:visible="showSubtask"
    :style="{ width: '90vw' }"
    :maximizable="true"
    :modal="true"
    :closable="false"
  >
    <Toast />
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="username">Agent Name</label>
        <Dropdown
          :filter="true"
          id="username"
          inputId="username"
          v-model="subtask.agent"
          :options="users"
          optionLabel="name"
          optionValue="username"
          placeholder="Select Agent"
        />
      </div>
      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="subTaskDetails">Details</label>
        <Dropdown
          :filter="true"
          id="subTaskDetails"
          v-model="subtask.details"
          :options="subTags"
          optionLabel="name"
          optionValue="id"
          placeholder="Select Details"
        />
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="endTime">Finish Time</label>
        <Calendar
          id="endTime"
          :monthNavigator="true"
          :yearNavigator="true"
          :showTime="true"
          hourFormat="12"
          :yearRange="yearRange"
          v-model="subtask.endTime"
          placeholder="MM/DD/YYYY hh:mm a"
        />
      </div>
    </div>

    <template #footer>
      <Button
        v-if="typeof subtask.index === 'undefined'"
        label="Add"
        icon="pi pi-plus"
        class="p-button-success"
        @click="addSubtask"
        autofocus
      />
      <Button
        v-if="subtask.index > -1"
        label="Update"
        icon="pi pi-pencil"
        class="p-button-warning"
        @click="updateSubtask"
        autofocus
      />
      <Button
        label="Close"
        icon="pi pi-times"
        @click="closeSubtask"
        class="p-button-text"
      />
    </template>
  </Dialog>
</template>

<script>
import { inject, ref, watch } from "vue";
import { useToast } from "primevue/usetoast";
export default {
  setup() {
    const store = inject("store");
    const toast = useToast();
    let src = store.tasks;
    const formData = ref({});
    const subtask = ref({});
    const showSubtask = ref(false);
    const visible = ref(false);
    const title = ref();
    // const originalRows = ref({});
    // const editingRows = ref([]);
    // Objects
    const today = new Date();
    const yearRange =
      (today.getFullYear() - 1).toString() +
      ":" +
      (today.getFullYear() + 1).toString();
    const cases = ref();
    const users = ref();
    const tags = ref();
    const subTags = ref();
    const status = ref([
      { name: "Assigned" },
      { name: "Approved" },
      { name: "Rejected" },
      { name: "Finished" },
      { name: "Deleted" },
    ]);
    // Validation
    function isExist() {
      return (
        formData.value.id.length > 0 && src.byId(formData.value.id).length > 0
      );
    }
    function validate() {
      return (
        formData.value.tagId.length > 0 &&
        formData.value.caseId.length > 0 &&
        formData.value.status.length > 0
      );
    }
    //  Subtask
    const onRowReorder = (event) => {
      formData.value.subtask = event.value;
    };
    function getSubtask(params) {
      let endTimeTemp = new Date();
      if (params.endTime) endTimeTemp.setTime(params.endTime);
      return {
        agent: params.agent,
        details: params.details,
        status: params.status,
        endTime: endTimeTemp,
      };
    }
    const editSubtask = (event) => {
      subtask.value = {};
      showSubtask.value = true;
      subtask.value = getSubtask(event.data);
      subtask.value.index = event.index;
    };

    const createSubtask = () => {
      subtask.value = {};
      showSubtask.value = true;
    };

    const closeSubtask = () => {
      subtask.value = {};
      showSubtask.value = false;
    };

    const updateSubtask = () => {
      formData.value.subtask[subtask.value.index] = getSubtask(subtask.value);
      showSubtask.value = false;
    };

    const deleteSubtask = (event) => {
      formData.value.subtask = formData.value.subtask.splice(event.index, 1);
    };
    // const onRowEditInit = (event) => {
    //   originalRows.value[event.index] = {
    //     ...formData.value.subtask[event.index],
    //   };
    // };
    // const onRowEditCancel = (event) => {
    //   formData.value.subtask[event.index] = originalRows.value[event.index];
    // };

    const addSubtask = () => {
      if (subtask.value.details) {
        subtask.value.status = "Assigned";
        formData.value.subtask.push(subtask.value);
        subtask.value = { agent: subtask.value.agent };
        toast.add({
          severity: "success",
          summary: "Subtask Added",
          detail: "Subtask added to the list.",
          life: 3000,
        });
      } else {
        toast.add({
          severity: "warn",
          summary: "Details Missing",
          detail: "Provide Details for Subtask",
          life: 3000,
        });
      }
    };
    // CRUD
    function setupSubmit() {
      // Unique Username
      let usernameList = [];
      if (formData.value.subtask) {
        formData.value.subtask.forEach((element) => {
          if (element.agent && store.user.byUsername(element.agent)[0]) {
            let username = store.user.byUsername(element.agent)[0].username;
            usernameList.push(username);
          }
        });

        formData.value.username = usernameList.filter((elem, pos, arr) => {
          return arr.indexOf(elem) == pos;
        });
        formData.value.username.forEach((username) => {
          let tag = tags.value.find(
            (element) => element.id == formData.value.tagId
          );
          store.table.sendNotification(
            username,
            "Tasks from TBCPL",
            tag.name
          );
        });
      }
      // Formet Time
      formData.value.startTime = new Date(formData.value.startTime).getTime();
      formData.value.subtask.forEach((element, index) => {
        formData.value.subtask[index].endTime = new Date(
          element.endTime
        ).getTime();
      });
    }
    const create = () => {
      if (validate() && !isExist()) {
        setupSubmit();
        store.table.createDoc(formData.value, "Tasks");
        close();
      }
    };
    const update = () => {
      if (validate) {
        setupSubmit();
        store.table.updateDoc(formData.value, "Tasks");

        close();
      }
    };
    const dalete = () => {
      store.table.deleteDoc(src.data, "Tasks");
      close();
    };
    const close = () => {
      formData.value = src.init();
      src.close();
    };
    // Watcher
    watch([store.table, src], () => {
      visible.value = src.create || src.update;

      if (visible.value) {
        formData.value.id = src.data.id;
        formData.value.username = src.data.username;
        formData.value.caseId = src.data.caseId;
        formData.value.tagId = src.data.tagId;
        formData.value.rank = src.data.rank;

        let startTime = new Date();
        startTime.setTime(src.data.startTime);
        formData.value.startTime = startTime;

        formData.value.subtask = src.data.subtask;

        formData.value.status = src.data.status;
        formData.value.time = src.data.time;
        formData.value.updated = src.data.updated;

        let caseList = [];
        store.cases.list.forEach((element) => {
          caseList.push({ id: element.id, name: element.name });
        });
        cases.value = caseList;

        let usersList = [];
        store.user.list.forEach((element) => {
          if (element.role != "Client") {
            usersList.push({ name: element.name, username: element.username });
          }
        });
        users.value = usersList;

        let tagList = [];
        store.tags.list.forEach((element) => {
          if (element.type.includes("Tasks")) {
            tagList.push({ name: element.tag, id: element.id });
          }
        });
        tags.value = tagList;

        let subTagsList = [];
        store.tags.list.forEach((element) => {
          if (element.type.includes("Subtasks")) {
            subTagsList.push({ name: element.tag, id: element.id });
          }
        });
        subTags.value = subTagsList;
      }

      if (src.create) title.value = "Create task";
      if (src.update) title.value = "Update Task";
    });

    return {
      src,
      store,
      visible,
      create,
      update,
      close,
      dalete,

      subtask,
      addSubtask,
      showSubtask,
      editSubtask,
      onRowReorder,

      // onRowEditInit,
      // onRowEditCancel,
      // editingRows,
      // originalRows,

      createSubtask,
      closeSubtask,
      updateSubtask,
      deleteSubtask,

      title,
      formData,
      validate,

      tags,
      subTags,
      toast,
      status,
      isExist,
      cases,
      users,
      yearRange,
    };
  },
};
</script>
