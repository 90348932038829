<template>
  <Toast />
  <!-- Header -->
  <Menu id="mainMenu" ref="menu" :model="src.menuItems" :popup="true" />
  <Toolbar
    class="p-p-0 p-shadow-1"
    :style="{ height: 'wrap_content', position: 'sticky', top: '0' }"
  >
    <template #left>
      <div class="p-d-flex p-flex-wrap p-jc-center">
        <Button
          icon="pi pi-bars"
          @click="toggle"
          aria-haspopup="true"
          aria-controls="overlay_menu"
          class="p-button-outlined p-m-1"
        />
        <h2 class="p-mb-1 p-mt-1 p-m-2">
          {{ src.selectedMenu }}
        </h2>
      </div>

      <Button
        v-if="advanceCount > '0'"
        label="&#8377;"
        class="p-button-warning p-m-1 p-px-2 p-py-1"
        :badge="advanceCount"
        @click="
          store.table.selectedMenu = 'Advance';
          store.table.selection = [];
        "
      />
      <Button
        v-if="expenceCount > '0'"
        label="&#8377;"
        class="p-button-success p-m-1 p-px-2 p-py-1"
        :badge="expenceCount"
        @click="
          store.table.selectedMenu = 'Expenses';
          store.table.selection = [];
        "
      />

      <Button
        v-if="timesheetCount > '0'"
        icon="pi pi-clock"
        :style="{ width: 'fit-content' }"
        class="p-button-info p-m-1 p-px-2 p-py-1"
        :badge="timesheetCount"
        @click="
          store.table.selectedMenu = 'Timesheet';
          store.table.selection = [];
        "
      />
    </template>

    <template #right>
      <div class="p-d-flex p-flex-wrap-reverse p-jc-between">
        <h3
          class="p-m-0 p-p-2"
          :style="{ color: '#2196F3' }"
          v-if="store.table.loggedUser"
        >
          {{ store.table.loggedUser.name }} (
          {{ store.table.loggedUser.role }} )
        </h3>
        <span class="p-input-icon-left p-m-1">
          <i class="pi pi-search" />
          <InputText
            type="text"
            v-model="filters['global'].value"
            placeholder="Search"
          />
        </span>
        <SplitButton
          :label="src.selectedMenu"
          icon="pi pi-plus"
          class="p-button-success p-m-1"
          :model="createBtn"
          @click="create()"
        />
        <Button
          icon="pi pi-pencil"
          class="p-m-1"
          v-if="src.selection.length == 1"
          @click="update(src.selection[0])"
          :disabled="!src.selection || !src.selection.length"
        />
        <SplitButton
          v-if="src.selection.length > 0"
          :badge="src.selection.length.toString()"
          label="Delete"
          icon="pi pi-trash"
          class="p-button-danger p-m-1"
          :model="splitBtn"
          @click="
            src.deleteDoc(src.selection);
            src.selection = [];
          "
          :disabled="!src.selection || !src.selection.length"
        />
        <Button
          icon="pi pi-upload"
          class="p-button-info p-m-1"
          @click="exportTable"
        />
        <Button
          icon="pi pi-briefcase"
          class="p-button-success p-m-1"
          @click="
            store.table.showLocation = true;
            store.table.locationData = 'Cases';
          "
        />
        <Button
          icon="pi pi-map-marker"
          class="p-button-warning p-m-1"
          @click="
            store.table.showLocation = true;
            store.table.locationData = 'Users';
          "
        />
      </div>
    </template>
  </Toolbar>
  <!-- Table -->
  <DataTable
    v-if="
      store.table.selectedMenu != 'Clients' &&
      store.table.selectedMenu != 'Reports'
    "
    showGridlines
    ref="dataTable"
    class="p-datatable-sm"
    selectionMode="multiple"
    responsiveLayout="scroll"
    v-model:filters="filters"
    v-model:selection="src.selection"
    :rows="10"
    :paginator="true"
    :style="{ height: '90vh', 'overflow-y': 'auto' }"
    scrollHeight="flex"
    :scrollable="true"
    :value="tableData"
    :dataKey="dataKey"
    :rowClass="rowClass"
    :metaKeySelection="false"
    :rowsPerPageOptions="[
      5, 10, 20, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000,
    ]"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
  >
    <template #empty> No data found. </template>
    <template #loading> Loading data. Please wait. </template>

    <Column
      :exportable="false"
      selectionMode="multiple"
      style="max-width: 37px"
      frozen
    ></Column>
    <Column
      v-for="col of tableColumns"
      :field="col.field"
      :header="col.header"
      :key="col.field"
      :sortable="true"
      :scrollable="true"
      :style="col.style"
    ></Column>
    <Column header="Actions" :exportable="false" style="max-width: 100px">
      <template #body="slotProps">
        <div class="p-d-flex">
          <Button
            icon="pi pi-pencil"
            class="p-button-sm p-button-raised p-button-rounded p-mr-2"
            @click="update(slotProps.data)"
          />

          <Button
            icon="pi pi-trash"
            class="p-button-sm p-button-raised p-button-rounded p-button-danger"
            @click="src.deleteDoc(slotProps.data)"
          />
        </div>
      </template>
    </Column>
  </DataTable>
  <!-- Clients -->
  <div
    v-if="
      store.table.selectedMenu == 'Clients' ||
      store.table.selectedMenu == 'Reports'
    "
    class="p-grid p-jc-evenly p-p-2 p-m-1"
  >
    <div
      v-for="client in tableData.filter((currentValue, index, array) => {
        if (filters['global'].value != null)
          return currentValue.name.includes(filters['global'].value);
        else return tableData;
      })"
      class="p-col p-shadow-2 p-m-1"
      :style="{
        'min-width': '250px',
        'border-radius': '12px',
        'background-color': isSelected(client.username),
      }"
      :key="client.username"
      @click="selectClient(client)"
      @dblclick="openClient(client)"
    >
      <h4
        class="p-m-0 p-p-1"
        for="client"
        @click="selectClient(client)"
        @dblclick="openClient(client)"
      >
        {{ client.name }}
      </h4>
    </div>
  </div>
</template>

<script>
import { inject, ref, watch } from "vue";
import { FilterMatchMode } from "primevue/api";
import { useToast } from "primevue/usetoast";
export default {
  setup() {
    const store = inject("store");
    const toast = useToast();
    const menu = ref();
    const src = store.table;
    const dataTable = ref();
    const tableColumns = ref();
    const tableData = ref();
    const dataKey = ref();
    const doc = ref();
    const splitBtn = ref();

    const expenceCount = ref("");
    const advanceCount = ref("");
    const timesheetCount = ref("");

    const selectClient = (clientData) => {
      switch (store.table.selectedMenu) {
        case "Clients":
          store.client.selectClient(clientData.username);
          break;

        case "Reports":
          store.cases.selectClient(clientData.username);
          break;

        default:
          break;
      }
    };

    const openClient = (clientData) => {
      switch (store.table.selectedMenu) {
        case "Clients":
          store.client.showClient(clientData.username);
          break;

        case "Reports":
          store.cases.showClient(clientData.username);
          break;

        default:
          break;
      }
    };

    const isSelected = (username) => {
      if (
        store.table.selection[0] &&
        store.table.selection[0].username == username
      )
        return "#E0FFFF";
      else return "#FFFFFF";
    };

    const rowClass = (data) => {
      return data.status;
    };

    const createBtn = ref([
      {
        label: "Users",
        icon: "pi pi-users",
        command: () => {
          store.table.selectedMenu = "Users";
          store.user.createDoc();
        },
      },
      {
        label: "Clients",
        icon: "pi pi-users",
        command: () => {
          store.table.selectedMenu = "Clients";
          store.client.createDoc();
        },
      },
      {
        label: "Cases",
        icon: "pi pi-briefcase",
        command: () => {
          store.table.selectedMenu = "Cases";
          store.cases.createDoc();
        },
      },
      {
        label: "Tags",
        icon: "pi pi-tags",
        command: () => {
          store.table.selectedMenu = "Tags";
          store.tags.createDoc();
        },
      },
      {
        label: "Tasks",
        icon: "pi pi-calendar",
        command: () => {
          store.table.selectedMenu = "Tasks";
          store.tasks.createDoc();
        },
      },
      {
        label: "Timesheet",
        icon: "pi pi-clock",
        command: () => {
          store.table.selectedMenu = "Timesheet";
          store.timesheet.createDoc();
        },
      },
      {
        label: "Expenses",
        icon: "pi pi-wallet",
        command: () => {
          store.table.selectedMenu = "Expenses";
          store.expenses.createDoc();
        },
      },
      {
        label: "Advance",
        icon: "pi pi-wallet",
        command: () => {
          store.table.selectedMenu = "Advance";
          store.advances.createDoc();
        },
      },
      {
        label: "Reports",
        icon: "pi pi-book",
        command: () => {
          store.table.selectedMenu = "Reports";
          store.reports.createDoc();
        },
      },
      {
        label: "Targets",
        icon: "pi pi-user",
        command: () => {
          store.table.selectedMenu = "Targets";
          store.targets.createDoc();
        },
      },
    ]);

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const toggle = (event) => {
      menu.value.toggle(event);

      src.selection = [];

      store.user.create = false;
      store.user.update = false;

      store.cases.create = false;
      store.cases.update = false;

      store.expenses.create = false;
      store.expenses.update = false;

      store.advances.create = false;
      store.advances.update = false;

      store.tags.create = false;
      store.tags.update = false;

      store.tasks.create = false;
      store.tasks.update = false;

      store.timesheet.create = false;
      store.timesheet.update = false;

      store.targets.create = false;
      store.targets.update = false;

      store.reports.create = false;
      store.reports.update = false;
    };

    const exportTable = () => {
      switch (src.selectedMenu) {
        case "Cases":
          if (src.selection.length > 0)
            store.exportReport.show(src.selection[0]);
          break;

        case "Clients":
          store.csv.export(
            tableColumns.value,
            tableData.value,
            src.selectedMenu.toString() + "-" + store.date.toDate().toString()
          );
          break;

        default:
          if (src.selection.length > 0)
            store.csv.export(
              src.selectedMenu == "Expenses" || src.selectedMenu == "Advance"
                ? tableColumns.value.concat([
                    { field: "caseId", header: "Case Id" },
                  ])
                : tableColumns.value,
              src.selection,
              src.selectedMenu.toString() + "-" + store.date.toDate().toString()
            );
          else dataTable.value.exportCSV();
          break;
      }
    };

    function create() {
      switch (src.selectedMenu) {
        case "Users":
          store.user.createDoc();
          break;

        case "Clients":
          store.client.createDoc();
          break;

        case "Cases":
          store.cases.createDoc();
          break;

        case "Expenses":
          store.expenses.createDoc();
          break;

        case "Advance":
          store.advances.createDoc();
          break;

        case "Reports":
          store.reports.createDoc();
          break;

        case "Tags":
          store.tags.createDoc();
          break;

        case "Tasks":
          store.tasks.createDoc();
          break;

        case "Timesheet":
          store.timesheet.createDoc();
          break;

        case "Targets":
          store.targets.createDoc();
          break;

        default:
          break;
      }
    }

    function update(data) {
      switch (src.selectedMenu) {
        case "Users":
          store.user.updateDoc(data);
          break;

        case "Clients":
          store.client.updateDoc(data);
          break;

        case "Cases":
          store.cases.updateDoc(data);
          break;

        case "Expenses":
          store.expenses.updateDoc(data);
          break;
        case "Advance":
          store.advances.updateDoc(data);
          break;

        case "Reports":
          store.reports.updateDoc(data);
          break;

        case "Tags":
          store.tags.updateDoc(data);
          break;

        case "Tasks":
          store.tasks.updateDoc(data);
          break;

        case "Timesheet":
          store.timesheet.updateDoc(data);
          break;

        case "Targets":
          store.targets.updateDoc(data);
          break;

        default:
          break;
      }
    }
    // Watcher
    watch(
      [
        src,
        store.user,
        store.cases,
        store.expenses,
        store.advances,
        store.reports,
        store.tags,
        store.tasks,
        store.timesheet,
        store.targets,
      ],
      () => {
        expenceCount.value = store.expenses.new.toString();
        advanceCount.value = store.advances.new.toString();
        timesheetCount.value = store.timesheet.new.toString();
        if (src.toast.show) {
          toast.add({
            severity: src.toast.severity,
            summary: src.toast.summary,
            detail: src.toast.detail,
            life: 3000,
          });
          src.toast.show = false;
        }
        switch (src.selectedMenu) {
          case "Users":
            dataKey.value = "username";
            splitBtn.value = store.user.splitBtn;
            tableData.value = store.user.list;
            tableColumns.value = store.user.columns;
            break;

          case "Clients":
            dataKey.value = "username";
            splitBtn.value = store.client.splitBtn;
            tableData.value = store.client.list;
            tableColumns.value = store.client.columns;
            break;

          case "Cases":
            dataKey.value = "id";
            splitBtn.value = store.cases.splitBtn;
            tableData.value = store.cases.list;
            tableColumns.value = store.cases.columns;
            break;

          case "Expenses":
            dataKey.value = "id";
            tableData.value = [];
            splitBtn.value = store.expenses.splitBtn;
            tableData.value = store.expenses.newList;
            tableColumns.value = store.expenses.columns;
            break;

          case "Advance":
            dataKey.value = "id";
            tableData.value = [];
            splitBtn.value = store.advances.splitBtn;
            tableData.value = store.advances.newList;
            tableColumns.value = store.advances.columns;
            break;

          case "Reports":
            dataKey.value = "username";

            splitBtn.value = store.client.splitBtn;
            // tableData.value = store.reports.list;
            tableData.value = store.client.list;
            tableColumns.value = store.client.columns;
            break;

          case "Tags":
            dataKey.value = "id";

            splitBtn.value = store.tags.splitBtn;
            tableData.value = store.tags.list;
            tableColumns.value = store.tags.columns;
            break;

          case "Tasks":
            dataKey.value = "id";

            splitBtn.value = store.tasks.splitBtn;
            tableData.value = store.tasks.list;
            tableColumns.value = store.tasks.columns;
            break;

          case "Timesheet":
            dataKey.value = "id";

            splitBtn.value = store.timesheet.splitBtn;
            tableData.value = store.timesheet.newList;
            tableColumns.value = store.timesheet.columns;
            break;

          case "Targets":
            dataKey.value = "id";

            splitBtn.value = store.targets.splitBtn;
            tableData.value = store.targets.list;
            tableColumns.value = store.targets.columns;
            break;

          default:
            tableData.value = [];
            tableColumns.value = [];
            break;
        }
      }
    );

    return {
      doc,
      menu,
      create,
      update,
      store,
      src,
      toggle,
      filters,
      dataKey,
      dataTable,
      tableData,
      tableColumns,
      exportTable,
      splitBtn,
      createBtn,
      rowClass,
      isSelected,
      selectClient,
      openClient,

      expenceCount,
      advanceCount,
      timesheetCount,
    };
  },
};
</script>

<style scoped>
::v-deep(.Deleted) {
  background-color: rgba(255, 150, 150, 0.4) !important;
}
::v-deep(.Blocked) {
  background-color: rgba(255, 222, 150, 0.4) !important;
}
::v-deep(.Rejected) {
  background-color: rgba(255, 222, 150, 0.4) !important;
}
::v-deep(.Completed) {
  background-color: rgba(150, 255, 150, 0.4) !important;
}
::v-deep(.Approved) {
  background-color: rgba(150, 255, 150, 0.4) !important;
}
::v-deep(.Finished) {
  background-color: rgba(150, 200, 255, 0.4) !important;
}
::v-deep(.Reimbursed) {
  background-color: rgba(150, 200, 255, 0.4) !important;
}
</style>