<template>
  <Dialog
    header="Rejection Details"
    v-model:visible="data.show"
    :style="{ width: '90vw' }"
    :maximizable="true"
    :modal="true"
    :closable="false"
  >
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-12">
        <label for="reject">Reason of Rejection / Remark</label>
        <Textarea
          id="reject"
          rows="1"
          v-model="rejectDetails"
          :autoResize="true"
          placeholder="Reason of Rejection / Remark"
        />
      </div>
    </div>

    <template #footer>
      <Button
        label="Reject"
        icon="pi pi-ban"
        @click="reject"
        class="p-button-raised p-button-danger"
      />

      <Button
        label="Close"
        icon="pi pi-times"
        @click="close"
        class="p-button-text p-mt-2"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import { inject, ref, watch } from "vue";
export default {
  setup() {
    const store = inject("store");
    const rejectDetails = ref("");
    const data = ref({
      show: false,
      data: [],
      status: "",
      path: "",
    });
    const src = store.table;

    const close = () => {
      src.rejectData.show = false;
      data.value.show = false;
    };

    const reject = () => {
      if (data.value.path == "Advance" || data.value.path == "Expenses")
        store.table.stausDoc(
          data.value.data,
          data.value.status,
          data.value.path,
          [
            {
              id: "tranId",
              data: rejectDetails.value,
            },
          ]
        );
      else
        store.table.stausDoc(
          data.value.data,
          data.value.status,
          data.value.path,
          [
            {
              id: "reject",
              data: rejectDetails.value,
            },
          ]
        );

      store.table.sendNotification(
        data.value.username,
        data.value.path + " Rejected",
        "Some of your entris rejected plese update them."
      );
      close();
    };

    // Watcher
    watch([src], () => {
      if (src.rejectData.show) {
        rejectDetails.value = "";
        data.value = src.rejectData;
      }
    });

    return {
      src,
      store,
      data,
      rejectDetails,

      close,
      reject,
    };
  },
};
</script>
