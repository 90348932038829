<template>
  <Dialog
    :header="title"
    v-model:visible="visible"
    :style="{ width: '90vw' }"
    :maximizable="true"
    :modal="true"
    :closable="false"
  >
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="username">Username</label>
        <InputText
          id="username"
          :disabled="src.update"
          v-model="formData.username"
          placeholder="Username"
          type="text"
        />
        <small class="p-error" v-if="formData.username.length == 0">
          Username is required.
        </small>
        <small class="p-error" v-if="src.create && usernameExist()">
          Username is used before try with diffrent Username!
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="code">Code</label>
        <InputText
          v-model="formData.code"
          id="code"
          placeholder="Code"
          type="text"
        />
        <small class="p-error" v-if="formData.code.length == 0">
          Code is required.
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="name">Full Name</label>
        <InputText
          v-model="formData.name"
          id="name"
          placeholder="Full Name"
          type="text"
        />
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="status">Status</label>
        <Dropdown
          id="status"
          inputId="status"
          v-model="formData.status"
          :options="status"
          optionLabel="name"
          optionValue="name"
          placeholder="Select status"
        />
        <small class="p-error" v-if="formData.status.length == 0">
          Status is required.
        </small>
      </div>

      <!-- <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="email">Email</label>
        <InputText
          id="email"
          v-model="formData.email"
          placeholder="Email Google"
          type="text"
        />
      </div> -->
    </div>
    <div v-if="src.update" class="p-fluid p-formgrid p-grid">
      <div class="p-col-12 p-md-4">
        <label>UID :</label>
        <small class="p-text-normal p-m-2">
          {{ src.data.uid }}
        </small>
      </div>
      <div class="p-col-12 p-md-4">
        <label>Created :</label>
        <small class="p-text-normal p-m-2">
          {{ store.date.toDateTime(formData.time) }}
        </small>
      </div>
      <div class="p-col-12 p-md-4">
        <label>Last Updated :</label>
        <small class="p-text-normal p-m-2">
          {{ store.date.toDateTime(formData.updated) }}
        </small>
      </div>
    </div>

    <template #footer>
      <Button
        v-if="src.create"
        label="Create"
        icon="pi pi-check"
        class="p-button-success"
        @click="create"
      />
      <Button
        v-if="src.update"
        label="Update"
        icon="pi pi-check"
        class="p-button-warning"
        @click="update"
      />
      <Button
        v-if="src.update"
        label="Delete"
        icon="pi pi-trash"
        class="p-button-danger"
        @click="dalete"
      />
      <Button
        label="Close"
        icon="pi pi-times"
        @click="close"
        class="p-button-text"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import { inject, ref, watch } from "vue";
export default {
  setup() {
    const store = inject("store");
    let src = store.client;
    const formData = ref({});
    const visible = ref(false);
    const title = ref("Create Client");
    const status = ref([
      { name: "Active" },
      { name: "Blocked" },
      { name: "Deleted" },
    ]);

    function validate() {
      return (
        formData.value.username.length > 0 && formData.value.code.length > 0
      );
    }
    function usernameExist() {
      return (
        formData.value.username.length > 0 &&
        src.byUsername(formData.value.username).length > 0
      );
    }

    function setupSubmit() {
      formData.value.role = "Client";
    }
    const create = () => {
      if (validate() && !usernameExist()) {
        setupSubmit();
        store.table.createDoc(formData.value,"Clients");
        close();
      }
    };
    const update = () => {
      if (validate()) {
        setupSubmit();
        store.table.updateDoc(formData.value,"Clients");
        close();
      }
    };
    const dalete = () => {
      store.table.deleteDoc(src.data,"Clients");
      close();
    };
    const close = () => {
      formData.value = src.init();
      src.close();
    };
    // Watcher
    watch([store.table, src], () => {
      formData.value = src.init();
      visible.value = src.create || src.update;

      if (src.create) title.value = "Create Client";

      if (src.update) {
        title.value = "Update Client";
        formData.value.username = src.data.username;
        formData.value.role = src.data.role;
        formData.value.code = src.data.code;
        formData.value.name = src.data.name;
        formData.value.status = src.data.status;
        formData.value.time = src.data.time;
        formData.value.updated = src.data.updated;
      }
    });

    return {
      store,
      visible,
      create,
      update,
      close,
      dalete,
      title,
      status,
      validate,
      usernameExist,

      src,
      formData,
    };
  },
};
</script>
