/*  Defaults    */
import { createApp } from 'vue'
const app = createApp(App)
import App from './App.vue'

/*  Import Plugins  */
import router from './router'
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'

/*  Import Components */
import Toast from 'primevue/toast'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import Editor from 'primevue/editor'
import OrganizationChart from 'primevue/organizationchart'

// DataTable
import Chip from 'primevue/chip'
import Chips from 'primevue/chips'
import Menu from 'primevue/menu'
import Column from 'primevue/column'
import Toolbar from 'primevue/toolbar'
import Calendar from 'primevue/calendar'
import Dropdown from 'primevue/dropdown'
import Textarea from 'primevue/textarea'
import DataTable from 'primevue/datatable'
import InputMask from 'primevue/inputmask'
import MultiSelect from 'primevue/multiselect'
import SplitButton from 'primevue/splitbutton'
import FileUpload from 'primevue/fileupload'
import Galleria from 'primevue/galleria'
import ProgressBar from 'primevue/progressbar'

/*  Plugins */
app.use(router)
app.use(ToastService)
app.use(PrimeVue, { ripple: true })

/*  Components  */
app.component('Chip', Chip)
app.component('Chips', Chips)
app.component('Menu', Menu)
app.component('Toast', Toast)
app.component('Column', Column)
app.component('Dialog', Dialog)
app.component('Button', Button)
app.component('Toolbar', Toolbar)
app.component('Textarea', Textarea)
app.component('Dropdown', Dropdown)
app.component('Calendar', Calendar)
app.component('InputText', InputText)
app.component('DataTable', DataTable)
app.component('InputMask', InputMask)
app.component('InputNumber', InputNumber)
app.component('MultiSelect', MultiSelect)
app.component('SplitButton', SplitButton)
app.component('FileUpload', FileUpload)
app.component('Galleria', Galleria)
app.component('ProgressBar', ProgressBar)
app.component('Editor', Editor)
app.component('OrganizationChart', OrganizationChart)

/*  Styles  */
import 'primevue/resources/themes/saga-blue/theme.css' //theme
import 'primevue/resources/primevue.min.css'          //core css
import 'primeicons/primeicons.css'                   //icons
import 'primeflex/primeflex.css'                    //prime flex

/*  Mount   */
app.mount('#app')