export const csv = {
    export(arrayHeader, arrayData, fileName = 'ExportedData', delimiter = ',') {
        let header = ""
        const fields = [];
        let row = [];
        arrayHeader.forEach(obj => {
            row.push(obj.header)
            fields.push(obj.field)
        });
        header += row.join(delimiter) + "\n";
        let csv = header;

        arrayData.forEach(obj => {
            row = []
            fields.forEach(element => {
                row.push("\"" + obj[element].toString() + "\"")
            });
            csv += row.join(delimiter) + "\n";
        });

        let csvData = new Blob([csv], { type: 'text/csv' });
        let csvUrl = URL.createObjectURL(csvData);

        let hiddenElement = document.createElement('a');
        hiddenElement.href = csvUrl;
        hiddenElement.target = '_blank';
        hiddenElement.download = fileName + '.csv';
        hiddenElement.click();
    }
}