import { reactive } from "vue"
import { Advance } from "./firebaseInit"
import store from "./index"

export const advances = reactive({
    list: [],
    data: {},
    create: false,
    update: false,

    new: 0,
    newList: [],

    show: false,
    selected: false,
    selectedType: false,

    splitBtn: [
        /*{
            label: "ApproveAll",
            icon: "pi pi-check",
            command: () => {
                store.user.getUser((user, data) => {
                    store.table.selection.forEach(element => {
                        if (element.status === "Submitted" || element.status === "Approved") store.table.stausDoc(element, "Approved by " + data.role)
                        store.table.selection = []
                    })
                })
            },
        },*/
        {
            label: "Approve",
            icon: "pi pi-check",
            command: () => {
                store.user.getUser((user, data) => {
                    store.table.selection.forEach(element => {
                        if (element.status === "Submitted" || element.status === "Rejected by " + data.role || data.role == "Accounts")
                            store.table.stausDoc(element, "Approved by " + data.role, "Advance")
                    })
                    store.table.selection = []
                })
            },
        },
        {
            label: "Reject",
            icon: "pi pi-times",
            command: () => {
                store.user.getUser((user, data) => {
                    let dataList = []
                    store.table.selection.forEach(element => {
                        if (element.status === "Submitted" || element.status === "Approved by " + data.role)
                            dataList.push(element)
                    })
                    store.table.rejectData = {
                        show: true,
                        data: dataList,
                        status: "Rejected by " + data.role,
                        path: "Advance",
                        username: data.username,
                    }
                    store.table.selection = []
                })
            },
        },
    ],
    columns: [
        { field: 'date_', header: 'Date', style: { 'min-width': '115px', 'max-width': '120px' } },
        { field: 'amount', header: 'Amount', style: { 'min-width': '100px', 'max-width': '110px' } },
        { field: 'agent_', header: 'Agent', style: { 'min-width': '120px', 'max-width': '150px' } },
        { field: 'case_', header: 'Case', style: { 'min-width': '150px', 'max-width': '200px' } },
        { field: 'tag_', header: 'Tag', style: { 'min-width': '150px' } },
        { field: 'details', header: 'Details', style: { 'min-width': '150px' } },
        { field: 'status', header: 'Status', style: { 'min-width': '90px', 'max-width': '100px' } },
    ],
    init() {
        return {
            id: "",
            caseId: "",
            amount: 0,
            tagId: "",
            tranId: "",
            details: "",
            username: "",

            status: "Submitted",
            date: "",
            time: "",
            updated: "",
        }
    },

    exportIt(advanceList, name) {
        let list = []
        advanceList.forEach((element) => {
            element.agent = store.user.list.find(
                (data) =>
                    data.username.toLowerCase() == element.username.toLowerCase()
            ).name;

            element.tagId_ = store.tags.list.find(
                (data) => data.id.toLowerCase() == element.tagId.toLowerCase()
            ).tag;

            element.case_ = store.cases.list.find(
                (data) => data.id == element.caseId
            ).name;

            element.date_ = store.date.toDateTime(element.date);
            element.time_ = store.date.toDateTime(element.time);
            element.updated_ = store.date.toDateTime(element.updated);
            list.push(element);
        });
        store.csv.export(
            [
                { field: "id", header: "Advance Id" },
                { field: "agent", header: "Agent" },
                { field: "username", header: "Username" },
                { field: "amount", header: "Amount" },
                { field: "tagId_", header: "Tag" },
                { field: "details", header: "Details" },
                { field: "status", header: "Status" },
                { field: "date_", header: "Date" },
                { field: "time_", header: "Time" },
                { field: "updated_", header: "Updated" },
                { field: "caseId", header: "Case Id" },
                { field: "case_", header: "Case Name" },
            ],
            list,
            "Advance_" + name + "_" + store.date.toDateTime(new Date())
        );
    },
    createDoc() {
        this.update = false
        this.create = false
        this.create = true
        this.data = this.init()
    },
    updateDoc(data) {
        this.create = false
        this.update = false
        this.update = true
        this.data = data
    },
    close() {
        this.create = false;
        this.update = false;
        this.data = this.init()
    },
    byId(id) {
        return this.list.filter(
            (data) => data.id.toLowerCase() == id.toLowerCase()
        )
    },
    sync(user) {
        let caseIdList = []
        store.cases.list.forEach((cases) => {
            cases.managerId.forEach((manager) => {
                if (manager == user.username) caseIdList.push(cases.id)
            })
        })
        let query = Advance.where('status', 'not-in', ['Deleted']).where('username', '==', user.username).orderBy("status", "desc").orderBy("updated", "desc")
        switch (user.role) {
            case "Admin":
                query = Advance.where('status', 'not-in', ['Deleted']).orderBy("status", "desc").orderBy("updated", "desc")
                break;

            case "Accounts":
                query = Advance.where('status', 'not-in', ['Deleted', 'Submitted', 'Rejected by Manager'])
                    .orderBy("status", "desc").orderBy("updated", "desc")
                break;

            case "Manager":

                if (caseIdList.length == 0) caseIdList.push('TBCPL00')
                if (caseIdList.length < 10) {
                    query = Advance.where('caseId', 'in', caseIdList).where('status', '!=', 'Deleted')
                        .orderBy("status", "desc").orderBy("updated", "desc")
                } else {
                    query = Advance/*.where('caseId', 'in', caseIdList)*/.where('status', '!=', 'Deleted')
                        .orderBy("status", "desc").orderBy("updated", "desc")
                }
                break;
        }


        return query.onSnapshot({ includeMetadataChanges: true }, (snapshot) => {
            this.list = []
            this.newList = []
            this.new = 0
            snapshot.forEach((doc) => {
                let data = doc.data()
                data.date_ = store.date.toDate(data.date)
                data.agent_ = (store.user.byUsername(data.username)[0]) ? store.user.byUsername(data.username)[0].name : "Deleted"
                data.case_ = (store.cases.byId(data.caseId)[0]) ? store.cases.byId(data.caseId)[0].name : "Deleted"
                if (store.tags.byId(data.tagId)[0]) data.tag_ = store.tags.byId(data.tagId)[0].tag
                this.list.push(data)
                switch (user.role) {
                    case "Accounts":
                        if (data.status == 'Approved by Manager') {
                            this.new++
                            this.newList.push(data)
                        }
                        break
                    case "Admin":
                        if (data.status == 'Approved by Manager' || data.status == 'Approved by Accounts') {
                            this.new++
                            this.newList.push(data)
                        }
                        break
                    case "Manager":
                        if (caseIdList.includes(data.caseId)) {
                            if (data.status == 'Submitted') {
                                this.new++
                                this.newList.push(data)
                            }
                        }
                        break;
                    default: if (data.status == 'Submitted') {
                        this.new++
                        this.newList.push(data)
                    }
                        break;
                }
            });
        }, (error) => { console.error("Advance error", error) })
    },
})