<template>
  <Dialog
    :header="title"
    v-model:visible="visible"
    :style="{ width: '90vw' }"
    :maximizable="true"
    :modal="true"
    :closable="false"
  >
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="tag">Tag Name</label>
        <InputText
          v-model="formData.tag"
          id="tag"
          placeholder="Tag Name"
          type="text"
        />
        <small class="p-error" v-if="formData.tag.length == 0">
          Tag Name is required.
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="id">Tag Id</label>
        <InputText
          id="id"
          :disabled="src.update"
          v-model="formData.id"
          placeholder="Tag Id"
          type="text"
        />
        <small class="p-error" v-if="formData.id.length == 0">
          Tag Id is required.
        </small>
        <small class="p-error" v-if="src.create && isExist()">
          Tag Id is used before try with diffrent id!
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="cap">Cap</label>
        <InputNumber
          id="cap"
          prefix="&#x20B9; "
          v-model="formData.cap"
          :min="0"
          :max="10000"
        />
      </div>
      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="type">Type</label>
        <MultiSelect
          id="type"
          v-model="formData.type"
          :filter="true"
          :options="types"
          optionLabel="name"
          optionValue="name"
          placeholder="Select Type"
          display="chip"
        />
        <small class="p-error" v-if="formData.type.length == 0">
          Type is required.
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="status">Status</label>
        <Dropdown
          id="status"
          inputId="status"
          v-model="formData.status"
          :options="status"
          optionLabel="name"
          optionValue="name"
          placeholder="Select status"
        />
        <small class="p-error" v-if="formData.status.length == 0">
          Status is required.
        </small>
      </div>
    </div>
    <div v-if="src.update" class="p-fluid p-formgrid p-grid">
      <div class="p-col-12 p-md-4">
        <label>Created :</label>
        <small class="p-text-normal p-m-2">
          {{ store.date.toDateTime(formData.time) }}
        </small>
      </div>
      <div class="p-col-12 p-md-4">
        <label>Last Updated :</label>
        <small class="p-text-normal p-m-2">
          {{ store.date.toDateTime(formData.updated) }}
        </small>
      </div>
    </div>
    <template #footer>
      <Button
        v-if="src.create"
        label="Create"
        icon="pi pi-check"
        class="p-button-success"
        @click="create"
      />
      <Button
        v-if="src.update"
        label="Update"
        icon="pi pi-check"
        class="p-button-warning"
        @click="update"
      />
      <Button
        v-if="src.update"
        label="Delete"
        icon="pi pi-trash"
        class="p-button-danger"
        @click="dalete"
      />
      <Button
        label="Close"
        icon="pi pi-times"
        @click="close"
        class="p-button-text"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import { inject, ref, watch } from "vue";
export default {
  setup() {
    const store = inject("store");
    let src = store.tags;
    const formData = ref({});
    const visible = ref(false);
    const title = ref();
    // Objects
    const types = ref([
      { name: "Advance" },
      { name: "Expenses" },
      { name: "Tasks" },
      { name: "Subtasks" },
      { name: "Timesheet" },
      { name: "Reports" },
    ]);
    const status = ref([{ name: "Active" }, { name: "Deleted" }]);
    // Validation
    function isExist() {
      return (
        formData.value.id.length > 0 && src.byId(formData.value.id).length > 0
      );
    }
    function validate() {
      return (
        formData.value.id.length > 0 &&
        formData.value.tag.length > 0 &&
        formData.value.type.length > 0 &&
        formData.value.status.length > 0
      );
    }
    // CRUD
    const create = () => {
      if (validate() && !isExist()) {
        store.table.createDoc(formData.value,"Tags");
        close();
      }
    };
    const update = () => {
      if (validate) {
        store.table.updateDoc(formData.value,"Tags");
        close();
      }
    };
    const dalete = () => {
      store.table.deleteDoc(src.data,"Tags");
      close();
    };
    const close = () => {
      formData.value = src.init();
      src.close();
    };
    // Watcher
    watch([store.table, src], () => {
      formData.value = src.init();
      visible.value = src.create || src.update;

      if (src.create) title.value = "Create Tag";
      if (src.update) {
        title.value = "Update Tag";
        formData.value.id = src.data.id;
        formData.value.tag = src.data.tag;
        formData.value.status = src.data.status;
        formData.value.type = src.data.type;
        formData.value.cap = src.data.cap;
        formData.value.time = src.data.time;
        formData.value.updated = src.data.updated;
      }
    });

    return {
      src,
      store,
      visible,
      create,
      update,
      close,
      dalete,
      title,
      formData,
      validate,

      types,
      status,
      isExist,
    };
  },
};
</script>
