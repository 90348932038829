/*  Imports */
import firebase from 'firebase/app'
import axios from 'axios'
import { Loader } from "@googlemaps/js-api-loader"
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'


/*  Configs  */
const firebaseConfig = {
    apiKey: "AIzaSyBd7hO6d2_lcCViZZLW6h51uTBA2QVbN4Q",
    authDomain: "tbcplapp.firebaseapp.com",
    projectId: "tbcplapp",
    storageBucket: "tbcplapp.appspot.com",
    messagingSenderId: "873633679201",
    appId: "1:873633679201:web:18678919b161b19eb06720",
    measurementId: "G-62MR9MW49C"
}
firebase.initializeApp(firebaseConfig)
firebase.firestore().enablePersistence().catch((err) => {
    if (err.code == 'failed-precondition') {
        console.error("Multiple tabs open, persistence can only be enabled in one tab at a a time.\n", err)
    } else if (err.code == 'unimplemented') {
        console.error("The current browser does not support all of the features required to enable persistence.\n", err)
    }
})

/*  Uitls */
const storage = firebase.storage()
const db = firebase.firestore()
const auth = firebase.auth()
const authObj = firebase.auth

/*  Collection References */
const Expenses = db.collection('Expenses')
const Advance = db.collection('Advance')
const Locations = db.collection('Locations')
const Reports = db.collection('Reports')
const Cases = db.collection('Cases')
const Tasks = db.collection('Tasks')
const Timesheet = db.collection('Timesheet')
const Users = db.collection('Users')
const Clients = db.collection('Clients')
const Tags = db.collection('Tags')
const Targets = db.collection('Targets')

/*  Storage Refrences   */
const imgsExpenses = storage.ref('Expenses')
const imgsReports = storage.ref('Reports')

/**Google Map */
const mapApi = new Loader({ apiKey: "AIzaSyBp3U0Vt1De3p6vnQRMvdC_XNhPjVoGk0k", version: "weekly" });



// Functions
function Create(collection, id, doc) {
    return db.collection(collection).doc(id).set(doc)
}
function Update(collection, id, doc) {
    return db.collection(collection).doc(id).update(doc)
}
function Delete(collection, id) {
    return db.collection(collection).doc(id).delete()
}

function sendNotification(title, details, nid) {
    const notification = {
        "data": {
            "title": title,
            "message": details
        },
        "to": nid
    };
    const headers = {
        "Content-Type": "application/json",
        "Authorization": "key=AAAAy2igB2E:APA91bGG3hwwv_Sd6fLGEKLoKIdURIE7bLMCZfaxPNQYZdY6C4a7QL1uYIkGH9l6Mz7N89WmldH2SPw8FeiiKM1HUY48NIodq2009P_HHR33SkrodF0M4_6_BY_rC9hSse9dPED74Ibw"
    };
    axios.post("https://fcm.googleapis.com/fcm/send", notification, { headers })
        .then(response => {
            this.articleId = response.data.id
            console.log("response", response);
        })
        .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
        });
}


/*
function getImages(ref, id, callback) {
    let images = []
    console.log("images" + ref, images);
    storage.ref(ref).child(id).listAll().then((res) => {
        res.items.forEach((itemRef) => {
            itemRef.getDownloadURL().then((url) => {
                let img = new Image();
                img.onload = function () {
                    images.push({
                        url: url,
                        alt: itemRef.name,
                        title: itemRef.name,
                        height: this.height,
                        width: this.width,
                    });
                    callback(images)
                    console.log("images" + ref, images);
                };
                img.src = url;
            }).catch((error) => {
                console.error("images" + ref, error);
            });
        });
    }).catch((error) => {
        console.error("images" + ref, error);
    });
}*/

async function getImgDimentions(url) {
    return new Promise((resolve, reject) => {
        let img = new Image()
        img.onload = () => resolve({
            height: img.height,
            width: img.width
        })
        img.onerror = reject
        img.src = url
    })
}
async function getImgs(ref, id) {
    let images = []
    let items = await (await storage.ref(ref).child(id).listAll()).items
    for (let i = 0; i < items.length; i++) {
        let url = await items[i].getDownloadURL()
        let img = await getImgDimentions(url)
        images.push({
            url: url,
            alt: items[i].name,
            title: items[i].name,
            height: img.height,
            width: img.width,
        });
    }
    return images
}

async function deleteImage(refrence, id, name,callback) {
    storage.ref(refrence).child(id + "/" + name).delete().then(() => {
        callback(null)
    }).catch((error) => {
        callback(error)
    });

}

function setImages(refrence, id, images, callback, name = "name") {
    console.log("setImages" + refrence, images);
    let ref = storage.ref(refrence)
    images.forEach(element => {
        let imgName = (name == "" || name == "name") ? element["name"] : name + "." + element["name"].split('.').pop();

        ref.child(id + "/" + imgName).put(element).then((snapshot) => {
            console.log('Uploaded a blob or file!', element);
            callback(snapshot)
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
        }).catch((error) => {
            console.error("setImages" + ref, error);
        });
    });

}
// export utils/refs
export {
    auth,
    mapApi,
    authObj,
    storage,
    Expenses,
    Advance,
    Locations,
    Reports,
    Cases,
    Tasks,
    Timesheet,
    Users,
    Clients,
    Tags,
    Targets,
    sendNotification,
    imgsExpenses,
    imgsReports,
    Create,
    Update,
    Delete,
    getImgs,
    setImages,
    deleteImage
}