<template>
  <Dialog
    :header="title"
    v-model:visible="visible"
    :style="{ width: '90vw' }"
    :maximizable="true"
    :modal="true"
    :closable="false"
  >
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="id">Timesheet Id</label>
        <InputText
          id="id"
          :disabled="src.update"
          v-model="formData.id"
          placeholder="Timesheet Id"
          type="text"
        />
        <small class="p-error" v-if="formData.id.length == 0">
          Timesheet Id is required.
        </small>
        <small class="p-error" v-if="src.create && isExist()">
          Timesheet Id is used before try with diffrent id!
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="details">Details</label>
        <Textarea
          id="details"
          rows="1"
          v-model="formData.details"
          :autoResize="true"
          placeholder="Details"
        />
        <small class="p-error" v-if="formData.details.length == 0">
          Details are required.
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="tagId">Tag</label>
        <Dropdown
          :filter="true"
          id="tagId"
          inputId="tagId"
          v-model="formData.tagId"
          :options="tags"
          optionLabel="name"
          optionValue="id"
          placeholder="Select tag"
        />
        <small class="p-error" v-if="formData.tagId.length == 0">
          Tag is required.
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="username">Username</label>
        <Dropdown
          :filter="true"
          id="username"
          inputId="username"
          v-model="formData.username"
          :options="users"
          optionLabel="name"
          optionValue="name"
          placeholder="Select User"
        />
        <small class="p-error" v-if="formData.username.length == 0">
          Username is required.
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="caseId">Case</label>
        <Dropdown
          :filter="true"
          id="caseId"
          inputId="caseId"
          v-model="formData.caseId"
          :options="cases"
          optionLabel="name"
          optionValue="name"
          placeholder="Select case"
        />
        <small class="p-error" v-if="formData.caseId.length == 0">
          Case is required.
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="startTime">Start Time</label>
        <Calendar
          id="startTime"
          :maxDate="maxDate"
          :minDate="minDate"
          :monthNavigator="true"
          :yearNavigator="true"
          :showTime="true"
          hourFormat="12"
          :yearRange="yearRange"
          v-model="formData.startTime"
          placeholder="MM/DD/YYYY hh:mm a"
        />
        <small class="p-error" v-if="formData.startTime.length == 0">
          Start Time is required.
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="endTime">End Time</label>
        <Calendar
          id="endTime"
          :maxDate="maxDate"
          :minDate="minDate"
          :monthNavigator="true"
          :yearNavigator="true"
          :showTime="true"
          hourFormat="12"
          :yearRange="yearRange"
          v-model="formData.endTime"
          placeholder="MM/DD/YYYY hh:mm a"
        />
        <small class="p-error" v-if="formData.endTime.length == 0">
          End Time is required.
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="status">Status</label>
        <Dropdown
          id="status"
          inputId="status"
          v-model="formData.status"
          :options="status"
          optionLabel="name"
          optionValue="name"
          placeholder="Select status"
        />
        <small class="p-error" v-if="formData.status.length == 0">
          Status is required.
        </small>
      </div>

      <div
        v-if="formData.status == 'Rejected'"
        class="p-field p-col-12 p-md-6 p-lg-4"
      >
        <label for="reject">Reason of Rejection / Remark</label>
        <Textarea
          id="reject"
          rows="1"
          v-model="formData.reject"
          :autoResize="true"
          placeholder="Reason of Rejection / Remark"
        />
      </div>
    </div>

    <div v-if="src.update" class="p-fluid p-formgrid p-grid">
      <div class="p-col-12 p-md-4">
        <label>Created :</label>
        <small class="p-text-normal p-m-2">
          {{ store.date.toDateTime(formData.time) }}
        </small>
      </div>
      <div class="p-col-12 p-md-4">
        <label>Last Updated :</label>
        <small class="p-text-normal p-m-2">
          {{ store.date.toDateTime(formData.updated) }}
        </small>
      </div>
    </div>
    <template #footer>
      <Button
        v-if="src.create"
        label="Create"
        icon="pi pi-check"
        class="p-button-success"
        @click="create"
      />
      <Button
        v-if="src.update"
        label="Update"
        icon="pi pi-check"
        class="p-button-warning"
        @click="update"
      />
      <Button
        v-if="src.update"
        label="Delete"
        icon="pi pi-trash"
        class="p-button-danger"
        @click="dalete"
      />
      <Button
        label="Close"
        icon="pi pi-times"
        @click="close"
        class="p-button-text"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import { inject, ref, watch } from "vue";
export default {
  setup() {
    const store = inject("store");
    let src = store.timesheet;
    const formData = ref({});
    const visible = ref(false);
    const title = ref();
    // Objects
    const today = new Date();
    const maxDate = ref(new Date());
    const minDate = ref(new Date());
    const yearRange =
      (today.getFullYear() - 10).toString() +
      ":" +
      today.getFullYear().toString();
    const cases = ref();
    const users = ref();
    const tags = ref();
    const status = ref([
      { name: "Submitted" },
      { name: "Approved" },
      { name: "Rejected" },
      { name: "Finished" },
      { name: "Deleted" },
    ]);
    // Validation
    function isExist() {
      return (
        formData.value.id.length > 0 && src.byId(formData.value.id).length > 0
      );
    }
    function validate() {
      return (
        formData.value.id.length > 0 &&
        formData.value.tagId.length > 0 &&
        formData.value.username.length > 0 &&
        formData.value.caseId.length > 0 &&
        formData.value.details.length > 0 &&
        formData.value.status.length > 0 &&
        formData.value.startTime.toString().length > 0 &&
        formData.value.endTime.toString().length > 0
      );
    }
    // CRUD
    function setupSubmit() {
      formData.value.date = new Date(formData.value.startTime).getTime();
      formData.value.startTime = new Date(formData.value.startTime).getTime();
      formData.value.endTime = new Date(formData.value.endTime).getTime();
    }
    const create = () => {
      if (validate() && !isExist()) {
        setupSubmit();
        store.table.createDoc(formData.value, "Timesheet");
        close();
      }
    };
    const update = () => {
      if (validate) {
        setupSubmit();
        store.table.sendNotification(
          formData.value.username,
          "Timesheet updated",
          "Some of your entris " +
            formData.value.status +
            ". plese update them."
        );
        store.table.updateDoc(formData.value, "Timesheet");
        close();
      }
    };
    const dalete = () => {
      store.table.deleteDoc(src.data, "Timesheet");
      close();
    };
    const close = () => {
      formData.value = src.init();
      src.close();
    };
    // Watcher
    watch([store.table, src], () => {
      formData.value = src.init();
      visible.value = src.create || src.update;

      if (src.create || src.update) {
        minDate.value.setDate(today.getDate() - 7);
        maxDate.value.setUTCHours(12, 60);
        let caseList = [];
        store.cases.list.forEach((element) => {
          caseList.push({ name: element.id });
        });
        cases.value = caseList;

        let usersList = [];
        store.user.list.forEach((element) => {
          usersList.push({ name: element.username });
        });
        users.value = usersList;

        let tagList = [];
        store.tags.list.forEach((element) => {
          if (element.type.includes("Timesheet")) {
            tagList.push({ name: element.tag, id: element.id });
          }
        });
        tags.value = tagList;
      }

      if (src.create) title.value = "Create Timesheet";
      if (src.update) {
        title.value = "Update Timesheet";

        formData.value.id = src.data.id;
        formData.value.username = src.data.username;
        formData.value.caseId = src.data.caseId;
        formData.value.tagId = src.data.tagId;
        formData.value.details = src.data.details;

        let dateVal = new Date();
        dateVal.setTime(src.data.date);
        formData.value.date = dateVal;

        let startTime = new Date();
        startTime.setTime(src.data.startTime);
        formData.value.startTime = startTime;

        let endTime = new Date();
        endTime.setTime(src.data.endTime);
        formData.value.endTime = endTime;

        formData.value.reject = src.data.reject;
        formData.value.status = src.data.status;
        formData.value.time = src.data.time;
        formData.value.updated = src.data.updated;
      }
    });

    return {
      src,
      store,
      visible,
      create,
      update,
      close,
      dalete,
      title,
      formData,
      validate,

      tags,
      status,
      isExist,
      maxDate,
      minDate,
      cases,
      users,
      yearRange,
    };
  },
};
</script>
