import { reactive } from "vue"
import { Cases } from "./firebaseInit"
import store from "./index"

export const cases = reactive({
    list: [],
    targets: [],
    data: {},
    create: false,
    update: false,

    show: false,
    selected: false,
    selectClient(clientUsername) {
        store.table.selection = store.client.byUsername(clientUsername)
        store.cases.selected = clientUsername
    },

    showClient(clientUsername) {
        store.cases.selectClient(clientUsername)
        this.show = false
        this.show = true
    },

    hideClient() {
        this.show = true
        this.show = false
    },

    splitBtn: [
        /*{
             label: "Activate",
            icon: "pi pi-check",
            command: () => {
                store.table.stausDoc(store.table.selection, "Active")
                store.table.selection = []
            },
        },
        {
            label: "Complete",
            icon: "pi pi-save",
            command: () => {
                store.table.stausDoc(store.table.selection, "Completed")
                store.table.selection = []
            },
        },*/
    ],
    columns: [
        { field: 'id', header: 'Id', style: { 'min-width': '80px', 'max-width': '100px' } },
        { field: 'clientId', header: 'Client', style: { 'min-width': '100px', 'max-width': '100px' } },
        { field: 'name', header: 'Name', style: { 'min-width': '100px' } },
        { field: 'managerList', header: 'Managers', style: { 'min-width': '100px' } },
        { field: 'targetList', header: 'Targets', style: { 'min-width': '100px' } },
        { field: 'status', header: 'Status', style: { 'min-width': '90px', 'max-width': '100px' } },
    ],
    init() {
        return {
            id: "",
            name: "",

            location: "",
            state: "",

            product: "",
            targets: [],

            clientId: "",
            managerId: [],

            status: "Lead",
            time: "",
            updated: "",
        }
    },
    createDoc(clientUsername = "") {
        this.update = false
        this.create = false
        this.create = true
        this.data = this.init()
        this.data.clientId = clientUsername
        store.cases.targets = []
    },
    updateDoc(data) {
        this.create = false
        this.update = false
        this.update = true
        this.data = this.init()
        this.data = data
        store.cases.targets = []
    },
    close() {
        this.create = false;
        this.update = false;
        this.data = this.init()
        store.cases.targets = []
    },
    byId(id) { return this.list.filter((data) => data.id == id) },
    byTarget(id) { return this.list.filter((data) => data.targets.includes(id)) },
    sync(callback = () => { }) {
        return Cases.where('status', 'not-in', ['Deleted',]).orderBy("status").orderBy("updated", "desc").onSnapshot({ includeMetadataChanges: true }, (snapshot) => {
            this.list = []
            snapshot.forEach((doc) => {
                let data = doc.data()
                data.managerList = " "
                if (data.managerId)
                    data.managerId.forEach(element => {
                        if (store.user.byUsername(element)[0])
                            data.managerList += store.user.byUsername(element)[0].name + ","
                    });
                data.managerList = data.managerList.slice(0, -1)

                data.targetList = " "
                if (data.targets)
                    data.targets.forEach(element => {
                        if (store.targets.byId(element)[0])
                            data.targetList += store.targets.byId(element)[0].name_ + ","
                    });
                data.targetList = data.targetList.slice(0, -1)
                this.list.push(data)
            });
            callback()
        }, (error) => { console.error("Cases error", error) })
    },
})