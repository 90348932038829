<template>
  <Dialog
    :header="title"
    v-model:visible="visible"
    :style="{ width: '98vw' }"
    :maximizable="true"
    :modal="true"
    :closable="false"
  >
    <DataTable
      showGridlines
      ref="dataTableRef"
      class="p-datatable-sm"
      responsiveLayout="scroll"
      :rows="10"
      :paginator="true"
      scrollHeight="flex"
      :scrollable="true"
      :value="tableData"
      dataKey="time"
      @row-click="
        (event) => {
          openOnMap(event);
        }
      "
      :metaKeySelection="false"
      :rowsPerPageOptions="[5, 10, 20, 50, 100]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    >
      <template #empty> No data found. </template>
      <template #loading> Loading data. Please wait. </template>

      <Column
        v-for="col of tableColumns"
        :field="col.field"
        :header="col.header"
        :key="col.field"
        :sortable="true"
        :scrollable="true"
        :style="col.style"
      />

      <Column header="Actions" :exportable="false" style="max-width: 70px">
        <template #body="slotProps">
          <div class="p-d-flex">
            <Button
              icon="pi pi-trash"
              class="
                p-button-sm p-button-raised p-button-rounded p-button-danger
              "
              @click="
                store.table.deleteDocPermanently(slotProps.data, 'Locations')
              "
            />
          </div>
        </template>
      </Column>
    </DataTable>

    <template #footer>
      <Button
        label="Show Path on Map"
        icon="pi pi-map-marker"
        @click="src.showMap = true"
        class="p-button-raised p-button-warning"
      />
      <Button
        label="Export"
        icon="pi pi-upload"
        @click="exportIt"
        class="p-button-raised"
      />
      <Button
        label="Close"
        icon="pi pi-times"
        @click="close"
        class="p-button-text p-mt-2"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import { inject, ref, watch } from "vue";
export default {
  setup() {
    const store = inject("store");
    let src = store.locations;
    const visible = ref(false);
    const title = ref();

    const dataTableRef = ref();
    const tableColumns = ref();
    const tableData = ref();

    const close = () => {
      src.show = true;
      src.show = false;
      src.unSync();
    };

    const exportIt = () => {
      src.exportIt(
        tableData.value.sort((a, b) => (a.time > b.time ? 1 : -1)),
        src.selected
      );
    };
    const openOnMap = (event) => {
      window.open(
        "https://maps.google.com/?q=" +
          event.data.Latitude +
          "," +
          event.data.Longitude
      );
    };

    // Watcher
    watch([src], () => {
      visible.value = src.show;

      if (visible.value) {
        title.value = "Locations of " + src.selected;
        tableData.value = src.list;
        tableColumns.value = src.columns;
      }
    });

    return {
      src,
      store,
      title,
      visible,

      close,
      dataTableRef,
      tableData,
      tableColumns,

      exportIt,
      openOnMap,
    };
  },
};
</script>
