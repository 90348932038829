import { table } from "./Table"
import { user } from "./Users"
import { client } from "./Clients"
import { cases } from "./Cases"
import { expenses } from "./Expenses"
import { advances } from "./Advance"
import { reports } from "./Reports"
import { tags } from "./Tags"
import { tasks } from "./Tasks"
import { timesheet } from "./Timesheet"
import { locations } from "./Locations"
import { targets } from "./Targets"
import { csv } from "./csv"
import { date } from "./date"
import { login } from "./Login"
import { toWordDoc } from "./word"
import { exportReport } from "./ReportTamplate"
import { exportTarget } from "./ExportTarget"
import { sendNotification, getImgs, deleteImage, mapApi } from "./firebaseInit"


let states = [{ name: "Andaman and Nicobar", lat: 11.66702557, long: 92.73598262 }, { name: "Andhra Pradesh", lat: 14.7504291, long: 78.57002559 },
{ name: "Arunachal Pradesh", lat: 27.10039878, long: 93.61660071 }, { name: "Assam", lat: 26.7499809, long: 94.21666744 }, { name: "Bihar", lat: 25.78541445, long: 87.4799727 },
{ name: "Chandigarh", lat: 30.71999697, long: 76.78000565 }, { name: "Chhattisgarh", lat: 22.09042035, long: 82.15998734 }, { name: "Dadra and Nagar Haveli", lat: 20.26657819, long: 73.0166178 },
{ name: "Delhi", lat: 28.6699929, long: 77.23000403 }, { name: "Goa", lat: 15.491997, long: 73.81800065 }, { name: "Gujarat", lat: 23.093505, long: 71.607036 },
{ name: "Haryana", lat: 28.45000633, long: 77.01999101 }, { name: "Himachal Pradesh", lat: 31.10002545, long: 77.16659704 },
{ name: "Jammu and Kashmir", lat: 34.29995933, long: 74.46665849 }, { name: "Jharkhand", lat: 23.80039349, long: 86.41998572 },
{ name: "Karnataka", lat: 12.57038129, long: 76.91999711 }, { name: "Kerala", lat: 8.900372741, long: 76.56999263 }, { name: "Lakshadweep", lat: 10.56257331, long: 72.63686717 },
{ name: "Madhya Pradesh", lat: 21.30039105, long: 76.13001949 }, { name: "Maharashtra", lat: 19.25023195, long: 73.16017493 },
{ name: "Manipur", lat: 24.79997072, long: 93.95001705 }, { name: "Meghalaya", lat: 25.57049217, long: 91.8800142 },
{ name: "Mizoram", lat: 23.71039899, long: 92.72001461 }, { name: "Nagaland", lat: 25.6669979, long: 94.11657019 },
{ name: "Odisha", lat: 19.82042971, long: 85.90001746 }, { name: "Puducherry", lat: 11.93499371, long: 79.83000037 },
{ name: "Punjab", lat: 31.51997398, long: 75.98000281 }, { name: "Rajasthan", lat: 26.44999921, long: 74.63998124 },
{ name: "Sikkim", lat: 27.3333303, long: 88.6166475 }, { name: "Tamil Nadu", lat: 12.92038576, long: 79.15004187 },
{ name: "Telangana", lat: 17.870768, long: 78.938002 },
{ name: "Tripura", lat: 23.83540428, long: 91.27999914 },
{ name: "Uttar Pradesh", lat: 27.59998069, long: 78.05000565 },
{ name: "Uttarakhand", lat: 30.32040895, long: 78.05000565 },
{ name: "West Bengal", lat: 22.58039044, long: 88.32994665 },
{ name: "Daman and Diu", lat: 20.402532, long: 72.851614 },]




export default {
    table,
    user,
    mapApi,
    client,
    cases,
    expenses,
    locations,
    advances,
    reports,
    tags,
    tasks,
    timesheet,
    targets,
    csv,
    date,
    login,
    states,
    exportTarget,
    sendNotification,
    toWordDoc,

    exportReport,
    getImgs,
    deleteImage
}