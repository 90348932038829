<template>
  <Dialog
    :header="title"
    v-model:visible="visible"
    :style="{ width: '90vw' }"
    :maximizable="true"
    :modal="true"
    :closable="false"
  >
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="id">Report Id</label>
        <InputText
          id="id"
          :disabled="src.update"
          v-model="formData.id"
          placeholder="Report Id"
          type="text"
        />
        <small class="p-error" v-if="formData.id.length == 0">
          Report Id is required.
        </small>
        <small class="p-error" v-if="src.create && isExist()">
          Report Id is used before try with diffrent id!
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="title">Title</label>
        <InputText
          id="title"
          :disabled="src.update"
          v-model="formData.title"
          placeholder="Photos Title"
          type="text"
        />
        <small class="p-error" v-if="formData.title.length == 0">
          Title is required.
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="details">Details</label>
        <Textarea
          id="details"
          rows="1"
          v-model="formData.details"
          :autoResize="true"
          placeholder="Details"
        />
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="tagId">Tag</label>
        <Dropdown
          :filter="true"
          id="tagId"
          inputId="tagId"
          v-model="formData.tagId"
          :options="tags"
          optionLabel="name"
          optionValue="id"
          placeholder="Select tag"
        />
        <small class="p-error" v-if="formData.tagId.length == 0">
          Tag is required.
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="username">Username</label>
        <Dropdown
          :filter="true"
          id="username"
          inputId="username"
          v-model="formData.username"
          :options="users"
          optionLabel="name"
          optionValue="name"
          placeholder="Select User"
        />
        <small class="p-error" v-if="formData.username.length == 0">
          Username is required.
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="caseId">Case</label>
        <Dropdown
          :filter="true"
          id="caseId"
          inputId="caseId"
          v-model="formData.caseId"
          :options="cases"
          optionLabel="name"
          optionValue="name"
          placeholder="Select case"
        />
        <small class="p-error" v-if="formData.caseId.length == 0">
          Case is required.
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="status">Status</label>
        <Dropdown
          id="status"
          inputId="status"
          v-model="formData.status"
          :options="status"
          optionLabel="name"
          optionValue="name"
          placeholder="Select status"
        />
        <small class="p-error" v-if="formData.status.length == 0">
          Status is required.
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="audios">Audios</label>
        <Textarea
          id="audios"
          rows="1"
          v-model="formData.audios"
          :autoResize="true"
          placeholder="Audio Links"
        />
      </div>
      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="videos">Videos</label>
        <Textarea
          id="videos"
          rows="1"
          v-model="formData.videos"
          :autoResize="true"
          placeholder="video Links"
        />
      </div>
    </div>

    <div v-if="src.update" class="p-fluid p-mb-2 p-formgrid p-grid">
      <div class="p-col-12 p-md-4">
        <label>Created :</label>
        <small class="p-text-normal p-m-2">
          {{ store.date.toDateTime(formData.time) }}
        </small>
      </div>
      <div class="p-col-12 p-md-4">
        <label>Last Updated :</label>
        <small class="p-text-normal p-m-2">
          {{ store.date.toDateTime(formData.updated) }}
        </small>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <FileUpload
          name="demo[]"
          :customUpload="true"
          @uploader="uploadImages"
          :multiple="true"
          accept="image/*"
          :maxFileSize="1000000"
        >
          <template #empty>
            <p>Drag and drop files.</p>
          </template>
        </FileUpload>
        <ProgressBar :value="progress" />
      </div>
    </div>

    <Galleria
      :value="images"
      :numVisible="7"
      containerStyle="max-width: 100%"
      :circular="true"
      :fullScreen="true"
      :showItemNavigators="true"
      :showThumbnails="true"
      v-model:visible="showImages"
    >
      <template #item="slotProps">
        <img
          :src="slotProps.item.url"
          :alt="slotProps.item.alt"
          style="width: 90vw; height: 80vh; object-fit: scale-down"
        />
      </template>
      <template #thumbnail="slotProps">
        <img
          :src="slotProps.item.url"
          :alt="slotProps.item.alt"
          style="width: 5vw; height: 5vh; object-fit: cover"
        />
      </template>
    </Galleria>

    <template #footer>
      <Button
        label="Images"
        icon="pi pi-images"
        @click="displayImages"
        class="p-button"
      />
      <Button
        v-if="src.create"
        label="Create"
        icon="pi pi-check"
        class="p-button-success"
        @click="create"
      />
      <Button
        v-if="src.update"
        label="Update"
        icon="pi pi-check"
        class="p-button-warning"
        @click="update"
      />
      <Button
        v-if="src.update"
        label="Delete"
        icon="pi pi-trash"
        class="p-button-danger"
        @click="dalete"
      />
      <Button
        label="Close"
        icon="pi pi-times"
        @click="close"
        class="p-button-text"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import { inject, ref, watch } from "vue";
export default {
  setup() {
    const store = inject("store");
    let src = store.reports;
    const images = ref();
    const progress = ref(0);
    const showImages = ref(false);
    const showVideos = ref(false);
    const formData = ref({});
    const visible = ref(false);
    const title = ref();

    const cases = ref();
    const users = ref();
    const tags = ref();

    const status = ref([
      { name: "Submitted" },
      { name: "Approved" },
      { name: "Rejected" },
      { name: "Finished" },
      { name: "Deleted" },
    ]);
    // Validation
    function isExist() {
      return (
        formData.value.id.length > 0 && src.byId(formData.value.id).length > 0
      );
    }

    function validate() {
      return (
        formData.value.id.length > 0 &&
        formData.value.tagId.length > 0 &&
        formData.value.caseId.length > 0 &&
        formData.value.username.length > 0 &&
        formData.value.status.length > 0 &&
        formData.value.title.length > 0
      );
    }
    // CRUD
    function setupSubmit() {
      formData.value.date = new Date(formData.value.date).getTime();
    }

    async function displayImages() {
      images.value = await store.getImgs("Reports", src.data.id);
      showImages.value = true;
      if (images.value.length==0) alert("There is no Images found.")
    }
    const create = () => {
      if (validate() && !isExist()) {
        setupSubmit();
        store.table.createDoc(formData.value, "Reports");
        close();
      }
    };
    const update = () => {
      if (validate) {
        setupSubmit();
        store.table.updateDoc(formData.value, "Reports");
        close();
      }
    };
    const dalete = () => {
      store.table.deleteDoc(src.data, "Reports");
      close();
    };
    const close = () => {
      formData.value = src.init();
      src.close();
    };
    function uploadImages(event) {
      if (formData.value.id.length > 0)
        store.table.setImages(
          store.table.selectedMenu,
          src.data.id,
          event.files,
          function (snapshot) {
            if ((snapshot.state, "success")) {
              progress.value = 0;
              console.log("event.files", event.files.shift());
            }
            progress.value =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          }
        );
      else alert("Report Id is required");
    }

    // Watcher
    watch([store.table, src], () => {
      formData.value = src.init();
      visible.value = src.create || src.update;

      if (src.create || src.update) {
        let caseList = [];
        store.cases.list.forEach((element) => {
          caseList.push({ name: element.id });
        });
        cases.value = caseList;

        let usersList = [];
        store.user.list.forEach((element) => {
          usersList.push({ name: element.username });
        });
        users.value = usersList;

        let tagList = [];
        store.tags.list.forEach((element) => {
          if (element.type.includes("Reports")) {
            tagList.push({ name: element.tag, id: element.id });
          }
        });
        tags.value = tagList;
      }

      if (src.create) title.value = "Create Report";
      if (src.update) {
        title.value = "Update Report";

        formData.value.id = src.data.id;
        formData.value.username = src.data.username;
        formData.value.caseId = src.data.caseId;
        formData.value.tagId = src.data.tagId;
        formData.value.details = src.data.details;
        formData.value.title = src.data.title;
        formData.value.audios = src.data.audios;
        formData.value.videos = src.data.videos;
        formData.value.status = src.data.status;
        formData.value.time = src.data.time;
        formData.value.updated = src.data.updated;
      }
    });

    return {
      src,
      store,
      visible,
      create,
      update,
      close,
      dalete,
      title,
      formData,
      validate,

      tags,
      status,
      isExist,
      cases,
      users,
      images,
      showImages,
      displayImages,
      showVideos,
      uploadImages,
      progress,
    };
  },
};
</script>
