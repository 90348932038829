<template>
  <Dialog
    :header="title"
    v-model:visible="visible"
    :style="{ width: '98vw' }"
    :maximizable="true"
    :modal="true"
    :closable="false"
  >
    <DataTable
      showGridlines
      ref="dataTable"
      class="p-datatable-sm"
      responsiveLayout="scroll"
      v-model:selection="src.selection"
      :rows="10"
      :paginator="true"
      scrollHeight="flex"
      :scrollable="true"
      :value="tableData"
      dataKey="id"
      @row-click="
        (event) => {
          src.updateDoc(event.data);
        }
      "
      :metaKeySelection="false"
      :rowsPerPageOptions="[5, 10, 20, 50, 100]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    >
      <template #empty> No data found. </template>
      <template #loading> Loading data. Please wait. </template>

      <!-- <Column
        :exportable="false"
        selectionMode="multiple"
        style="max-width: 37px"
        frozen
      /> -->
      <Column
        v-for="col of tableColumns"
        :field="col.field"
        :header="col.header"
        :key="col.field"
        :sortable="true"
        :scrollable="true"
        :style="col.style"
      />
      <!-- <Column header="Actions" :exportable="false" style="max-width: 100px">
        <template #body="slotProps">
          <div class="p-d-flex">
            <Button
              icon="pi pi-pencil"
              class="p-button-sm p-button-raised p-button-rounded p-mr-2"
              @click="src.updateDoc(slotProps.data)"
            />

            <Button
              icon="pi pi-trash"
              class="
                p-button-sm p-button-raised p-button-rounded p-button-danger
              "
              @click="store.table.deleteDoc(slotProps.data, 'Cases')"
            />
          </div>
        </template>
      </Column> -->
    </DataTable>

    <template #footer>
      <Button
        label="New Case"
        icon="pi pi-calendar"
        @click="createCase"
        class="p-button-raised p-button-secondary"
      />
      <Button
        label="Close"
        icon="pi pi-times"
        @click="close"
        class="p-button-text"
      />
    </template>
  </Dialog>
</template>

<script>
import { inject, ref, watch } from "vue";
export default {
  setup() {
    const store = inject("store");
    let src = store.cases;
    const visible = ref(false);
    const title = ref();

    const dataTable = ref();
    const tableColumns = ref();
    const tableData = ref();

    const close = () => {
      store.client.hideClient();
    };
    const createCase = () => {
      src.createDoc(store.client.selected);
    };

    // Watcher
    watch([store.table, store.client, src], () => {
      visible.value =
        store.client.show && store.table.selectedMenu == "Clients";

      if (visible.value) {
        let client = store.client.byUsername(store.client.selected)[0];
        if (client) {
          title.value = "Cases of " + client.name;
          tableData.value = src.list.filter((data) => {
            if (store.table.selection[0])
              return data.clientId == store.table.selection[0].username;
            else return [];
          });
          tableColumns.value = src.columns;
        }
      }
    });

    return {
      src,
      store,
      title,
      visible,

      close,
      dataTable,
      tableData,
      tableColumns,

      createCase,
    };
  },
};
</script>
