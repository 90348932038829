<template>
  <Dialog
    header=""
    v-model:visible="visible"
    :modal="true"
    :closable="false"
    class="p-dialog-maximized"
  >
    <div id="targetTemplate" :style="{ 'border-style': 'solid' }" class="p-p-2">
      <h1
        class="p-py-2 p-m-0"
        :style="{
          textAlign: 'center',
          backgroundColor: brandColor,
          color: 'rgb(255,255,255)',
        }"
      >
        Target Details
      </h1>
      <p
        class="p-p-0 p-m-0"
        :style="{
          fontSize: '5px',
          backgroundColor: 'OrangeRed',
        }"
      >
        &emsp;
      </p>
      <!-- <br />
      <h2 :style="{ color: brandColor }">Target Details</h2> -->
      <table
        :style="{
          width: '100%',
          border: '1px solid black',
          borderCollapse: 'collapse',
        }"
      >
        <tr v-if="formData.name.length > 0">
          <td
          class="p-pl-2"
            :style="{
              border: '1px solid black',
              borderCollapse: 'collapse',
              fontWeight: 'bold',
            }"
          >
            Name
          </td>
          <td
          class="p-pl-2"
            :style="{ border: '1px solid black', borderCollapse: 'collapse' }"
            v-for="item in formData.name"
            :key="item"
          >
            {{ item }}
          </td>
        </tr>
        <tr v-if="formData.phone.length > 0">
          <td
          class="p-pl-2"
            :style="{
              border: '1px solid black',
              borderCollapse: 'collapse',
              fontWeight: 'bold',
            }"
          >
            Phone Number
          </td>
          <td
          class="p-pl-2"
            :style="{ border: '1px solid black', borderCollapse: 'collapse' }"
            v-for="item in formData.phone"
            :key="item"
          >
            {{ item }}
          </td>
        </tr>
        <tr v-if="formData.address.length > 0">
          <td
          class="p-pl-2"
            :style="{
              border: '1px solid black',
              borderCollapse: 'collapse',
              fontWeight: 'bold',
            }"
          >
            Address
          </td>
          <td
          class="p-pl-2"
            :style="{ border: '1px solid black', borderCollapse: 'collapse' }"
            v-for="item in formData.address"
            :key="item"
          >
            {{ item }}
          </td>
        </tr>
        <tr v-if="formData.email.length > 0">
          <td
          class="p-pl-2"
            :style="{
              border: '1px solid black',
              borderCollapse: 'collapse',
              fontWeight: 'bold',
            }"
          >
            Email Id
          </td>
          <td
          class="p-pl-2"
            :style="{ border: '1px solid black', borderCollapse: 'collapse' }"
            v-for="item in formData.email"
            :key="item"
          >
            {{ item }}
          </td>
        </tr>
        <tr v-if="formData.website.length > 0">
          <td
          class="p-pl-2"
            :style="{
              border: '1px solid black',
              borderCollapse: 'collapse',
              fontWeight: 'bold',
            }"
          >
            Website
          </td>
          <td
          class="p-pl-2"
            :style="{ border: '1px solid black', borderCollapse: 'collapse' }"
            v-for="item in formData.website"
            :key="item"
          >
            {{ item }}
          </td>
        </tr>

        <tr v-if="formData.company.length > 0">
          <td
          class="p-pl-2"
            :style="{
              border: '1px solid black',
              borderCollapse: 'collapse',
              fontWeight: 'bold',
            }"
          >
            Company Name
          </td>
          <td
          class="p-pl-2"
            :style="{ border: '1px solid black', borderCollapse: 'collapse' }"
            v-for="item in formData.company"
            :key="item"
          >
            {{ item }}
          </td>
        </tr>

        <tr>
          <td
          class="p-pl-2"
            :style="{
              border: '1px solid black',
              borderCollapse: 'collapse',
              fontWeight: 'bold',
            }"
          >
            Target Id
          </td>
          <td
          class="p-pl-2"
            :style="{ border: '1px solid black', borderCollapse: 'collapse' }"
          >
            {{ formData.id }}
          </td>
        </tr>
        <tr>
          <td
          class="p-pl-2"
            :style="{
              border: '1px solid black',
              borderCollapse: 'collapse',
              fontWeight: 'bold',
            }"
          >
            Created On
          </td>
          <td
          class="p-pl-2"
            :style="{ border: '1px solid black', borderCollapse: 'collapse' }"
          >
            {{ store.date.toDateTime(formData.time) }}
          </td>
        </tr>
      </table>
      <h2 v-if="formData.images.length > 0" :style="{ color: brandColor }">
        Target Photos
      </h2>
      <table
        :style="{
          width: '100%',
        }"
      >
        <tr v-for="(sub, sn) in formData.images.chunk(4)" :key="sn">
          <td v-for="(data, index) in sub" :key="index">
            <span style="mso-no-proof: yes">
              <img
                height="300"
                :width="(data.width / data.height) * 300"
                :src="data.url"
              />
            </span>
            <br>
            <span style="font-size: 10pt; line-height: 105%">
              Fig.{{ index + 1 }}
              {{ data.title.split(".").slice(0, -1).join(".") }}
            </span>
            <!-- <img
              :src="data.url"
              alt="Photo"
              width="290"
              :height="(data.height / data.width) * 290"
            />
            <h4 :style="{ textAlign: 'center', margin: '0' }">
              {{ data.title.split(".").slice(0, -1).join(".") }}
            </h4> -->
          </td>
        </tr>
      </table>
    </div>

    <template #footer>
      <Button
        label="Export"
        icon="pi pi-upload"
        @click="exportIt"
        class="p-button-success"
      />
      <Button
        label="Close"
        icon="pi pi-times"
        @click="close"
        class="p-button-text"
      />
    </template>
  </Dialog>
</template>

<script>
import { inject, ref, watch } from "vue";

export default {
  setup() {
    const store = inject("store");
    let src = store.exportTarget;
    const visible = ref(false);
    const formData = ref({});
    const brandColor = "#1976D2";

    const close = () => {
      formData.value = store.targets.init();
      src.visible = false;
    };

    const exportIt = () => {
      store.toWordDoc(
        "True Buddy Consulting Private Limited",
        "targetTemplate"
      );
    };

    // Watcher
    watch([src], () => {
      formData.value = src.data;
      visible.value = src.visible;
    });

    return {
      src,
      store,
      close,
      formData,
      visible,
      exportIt,
      brandColor,
    };
  },
};
</script>
