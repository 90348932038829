<template>
  <Dialog
    :header="title"
    v-model:visible="visible"
    :style="{ width: '95vw' }"
    :maximizable="true"
    :modal="true"
    :closable="false"
  >
    <!-- <OrganizationChart :style="{ width: '90vw' }" :value="treeData">
      <template #default="slotProps">
        <span>{{ slotProps.node.data.label }}</span>
      </template>
    </OrganizationChart> -->

    <OrganizationChart
      :value="treeData"
      :collapsible="true"
      v-model:selectionKeys="selection"
    >
      <template #default="slotProps">
        <div
          :style="{
            'background-color': '#2196F3',
            color: '#ffffff',
            padding: '.5em',
          }"
          @click="onClick(slotProps.node.data)"
          @dblclick="onDblClick(slotProps.node.data)"
        >
          {{ slotProps.node.data.name.join(" , ") }}
        </div>
        <div
          :style="{ padding: '.5em', 'text-align': 'left' }"
          @click="onClick(slotProps.node.data)"
          @dblclick="onDblClick(slotProps.node.data)"
        >
          <p v-if="slotProps.node.data.company.length > 0">
            Company &emsp;:&emsp; {{ slotProps.node.data.company.join(" , ") }}
          </p>
          <p v-if="slotProps.node.data.phone.length > 0">
            Phone &emsp;&emsp;&ensp;:&emsp;
            {{ slotProps.node.data.phone.join(" , ") }}
          </p>
          <p v-if="slotProps.node.data.email.length > 0">
            Email &emsp;&emsp;&ensp; :&emsp;
            {{ slotProps.node.data.email.join(" , ") }}
          </p>
          <p v-if="slotProps.node.data.address.length > 0">
            Address &emsp;&ensp;:&emsp;
            {{ slotProps.node.data.address.join(" , ") }}
          </p>
          <p v-if="slotProps.node.data.website.length > 0">
            Website &ensp;:&emsp; {{ slotProps.node.data.website.join(" , ") }}
          </p>
        </div>
      </template>
    </OrganizationChart>

    <template #footer>
      <Button
        label="Close"
        icon="pi pi-times"
        @click="close"
        class="p-button-text"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import { inject, ref, watch } from "vue";
export default {
  setup() {
    const store = inject("store");
    const src = store.targets;
    const visible = ref(false);
    const title = ref("");
    const close = () => {
      visible.value = false;
      src.showTree = false;
    };

    const selection = ref({});
    const treeData = ref({});

    const onClick = (data) => {
      src.data = data;
    };

    const onDblClick = (data) => {
      src.showTree = false;
      visible.value = false;
      let target = store.targets.list.find((element) => element.id == data.id);
      if (target) store.targets.updateDoc(target);
    };

    // Data Fatchers

    const getParent = (data) => {
      let tree = {
        key: data.id,
        data: data,
        children: getChildren(data),
      };

      let parent = data;
      while (parent) {
        parent = src.list.find((target) => target.id == parent.link);
        if (parent)
          tree = {
            key: parent.id,
            data: parent,
            children: [
              {
                key: tree.id,
                data: tree.data,
                children: tree.children,
              },
            ],
          };
      }
      return tree;
    };

    const getChildren = (data) => {
      let children = [];
      src.list
        .filter((target) => target.link == data.id)
        .forEach((target) => {
          children.push({
            key: target.id,
            data: target,
            children: getChildren(target),
          });
        });
      return children;
    };
    // Watcher
    watch([store.table, src, store.user], () => {
      visible.value = src.showTree;
      if (visible.value) {
        title.value = "Target Treee of " + src.data.name.join(" , ");
        treeData.value = getParent(src.data);
      }
    });

    return {
      src,
      title,
      store,

      visible,
      treeData,

      close,
      onClick,
      onDblClick,

      selection,
    };
  },
};
</script>
<style scoped>
p {
  padding: 0px;
  margin: 0px;
}
</style>
