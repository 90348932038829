import { reactive } from "vue"
import { Users } from "./firebaseInit"
import store from "./index"

export const user = reactive({
    list: [],
    data: {},

    create: false,
    update: false,

    splitBtn: [
        {
            label: "Activate",
            icon: "pi pi-check",
            command: () => {
                store.table.stausDoc(store.table.selection, "Active")
                store.table.selection = []
            },
        },
        {
            label: "Block",
            icon: "pi pi-ban",
            command: () => {
                store.table.stausDoc(store.table.selection, "Blocked")
                store.table.selection = []
            },
        },
    ],
    columns: [
        { field: 'username', header: 'Username', style: { 'min-width': '120px', 'max-width': '200px' } },
        { field: 'name', header: 'Name', style: { 'min-width': '150px' } },
        { field: 'caseList', header: 'Cases', style: { 'min-width': '150px' } },
        { field: 'email', header: 'Email', style: { 'min-width': '210px', 'max-width': '240px' } },
        { field: 'phone', header: 'Phone', style: { 'min-width': '110px', 'max-width': '110px' } },
        { field: 'role', header: 'Role', style: { 'min-width': '100px', 'max-width': '100px' } },
        { field: 'status', header: 'Status', style: { 'min-width': '90px', 'max-width': '100px' } },
    ],
    createDoc() {
        this.create = false
        this.create = true
        this.update = false
        this.data = this.init()
    },
    updateDoc(data) {
        this.create = false
        this.update = false
        this.update = true
        this.data = data
    },
    close() {
        this.create = false;
        this.update = false;
        this.data = this.init()
    },
    init() {
        return {
            uid: "",
            nid: "",
            username: "",
            phone: "",
            time: "",
            role: "",
            name: "",
            status: "Active",
            email: "",
            dob: "",
            address: "",
            updated: "",
            cases: [],
        }
    },
    byUsername(username) {
        return this.list.filter(
            (data) => data.username.toLowerCase() == username.toLowerCase()
        )
    },
    getUser(callback) {
        store.login.auth.onAuthStateChanged(function (user) {
            if (user && !user.isAnonymous) {
                let userData = store.user.list.find((data) => data.username == user.displayName)
                callback(user, userData)
            } else callback(false, false)
        });
    },
    sync(callback = () => { }) {
        return Users.where('status', 'not-in', ['Deleted',]).orderBy("status").orderBy("updated", "desc").onSnapshot({ includeMetadataChanges: true }, (snapshot) => {
            this.list = []
            snapshot.forEach((doc) => {
                let data = doc.data()
                data.caseList = data.cases.join(" , ")
                this.list.push(data)
            });
            callback()
        }, (error) => { console.error("Users error", error) })
    },
})