<template>
  <Dialog
    :header="title"
    v-model:visible="visible"
    :style="{ width: '98vw' }"
    :maximizable="true"
    :modal="true"
    :closable="false"
  >
    <div class="p-grid p-jc-evenly p-p-2 p-m-1">
      <div
        v-for="cases in casesList"
        class="p-col p-shadow-2 p-m-1"
        :style="{
          'min-width': '250px',
          'border-radius': '12px',
          'background-color': isCaseSelected(cases),
        }"
        :key="cases.id"
        @click="selectCase(cases)"
      >
        <h4 class="p-m-0 p-p-1" for="cases" @click="selectCase(cases)">
          {{ cases.name }}
        </h4>
      </div>
    </div>
    <template #footer>
      <Button
        label="New Case"
        icon="pi pi-calendar"
        @click="createCase"
        class="p-button-raised p-button-secondary"
      />
      <Button
        label="Close"
        icon="pi pi-times"
        @click="hideCases"
        class="p-button-text"
      />
    </template>
  </Dialog>

  <Dialog
    :header="agentTitle"
    v-model:visible="agentsVisible"
    :style="{ width: '96vw' }"
    :maximizable="true"
    :modal="true"
    :closable="false"
  >
    <div class="p-grid p-jc-evenly p-p-2 p-m-1">
      <div
        v-for="agent in agentsList"
        class="p-col p-shadow-2 p-m-1"
        :style="{
          'min-width': '250px',
          'border-radius': '12px',
          'background-color': isAgentSelected(agent),
        }"
        :key="agent.username"
        @click="selectAgent(agent)"
      >
        <h4 class="p-m-0 p-p-1" for="cases" @click="selectAgent(agent)">
          {{ agent.name }}
        </h4>
      </div>
    </div>

    <DataTable
      v-if="reportsVisible"
      showGridlines
      dataKey="id"
      ref="dataTable"
      scrollHeight="flex"
      class="p-datatable-sm"
      responsiveLayout="scroll"
      :rows="10"
      :paginator="true"
      :scrollable="true"
      :value="reportList"
      :metaKeySelection="false"
      :rowsPerPageOptions="[5, 10, 20, 50, 100]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    >
      <template #empty> No data found. </template>
      <template #loading> Loading data. Please wait. </template>

      <!-- <Column
        :exportable="false"
        selectionMode="multiple"
        style="max-width: 37px"
        frozen
      /> -->

      <Column
        v-for="col of store.reports.columns"
        :field="col.field"
        :header="col.header"
        :key="col.field"
        :sortable="true"
        :scrollable="true"
        :style="col.style"
      />

      <Column header="Actions" :exportable="false" style="max-width: 100px">
        <template #body="slotProps">
          <div class="p-d-flex">
            <Button
              icon="pi pi-pencil"
              class="p-button-sm p-button-raised p-button-rounded p-mr-2"
              @click="store.reports.updateDoc(slotProps.data, 'Reports')"
            />

            <Button
              icon="pi pi-trash"
              class="p-button-sm p-button-raised p-button-rounded p-button-danger"
              @click="store.table.deleteDoc(slotProps.data, 'Reports')"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <template #footer>
      <Button
        label="Downoad Report"
        icon="pi pi-upload"
        @click="exportReport"
        class="p-button-raised p-button-secondary"
      />
      <Button
        label="Close"
        icon="pi pi-times"
        @click="hideAgents"
        class="p-button-text"
      />
    </template>
  </Dialog>
</template>

<script>
import { inject, ref, watch } from "vue";
export default {
  setup() {
    const store = inject("store");
    let src = store.cases;

    const reportList = ref([]);

    const visible = ref(false);
    const agentsVisible = ref(false);
    const reportsVisible = ref(false);

    const title = ref();
    const agentTitle = ref();

    const casesList = ref();
    const agentsList = ref();

    const selectedCase = ref({});
    const selectedAgent = ref({});

    const selectCase = (caseData) => {
      selectedCase.value = caseData;
      agentTitle.value = "Agents in " + caseData.name;
      agentsVisible.value = true;

      agentsList.value = store.user.list.filter((data) => {
        if (selectedCase.value.managerId)
          return data.cases.includes(selectedCase.value.id);
        else return [];
      });
    };

    const selectAgent = (agentData) => {
      reportsVisible.value = true;
      selectedAgent.value = agentData;
      reportList.value = store.reports.list.filter((data) => {
        if (selectedAgent.value.username) {
          return (
            selectedAgent.value.username == data.username &&
            data.caseId == selectedCase.value.id
          );
        } else return [];
      });
    };

    const createCase = () => {
      src.createDoc(src.selected);
    };

    const exportReport = () => {
      store.exportReport.show(selectedCase.value);
    };

    const hideCases = () => {
      src.hideClient();
      selectedCase.value = {};
    };

    const hideAgents = () => {
      agentsVisible.value = false;
      reportsVisible.value = false;
      selectedAgent.value = {};
    };

    const isCaseSelected = (cases) => {
      if (selectedCase.value && selectedCase.value.id == cases.id)
        return "#E0FFFF";
      else return "#FFFFFF";
    };

    const isAgentSelected = (agent) => {
      if (selectedAgent.value && selectedAgent.value.username == agent.username)
        return "#E0FFFF";
      else return "#FFFFFF";
    };

    // Watcher
    watch([store.table, store.user, store.reports, store.client, src], () => {
      visible.value = src.show && store.table.selectedMenu == "Reports";

      if (visible.value) {
        let client = store.client.byUsername(src.selected)[0];
        if (client) {
          title.value = "Cases of " + client.name;

          casesList.value = src.list.filter((data) => {
            if (store.table.selection[0])
              return data.clientId == store.table.selection[0].username;
            else return [];
          });
        }
      }
    });

    return {
      src,
      store,
      title,
      visible,

      exportReport,

      isCaseSelected,
      selectedCase,
      createCase,
      selectCase,
      hideCases,
      casesList,

      isAgentSelected,
      agentsVisible,
      selectedAgent,
      selectAgent,
      hideAgents,
      agentsList,
      agentTitle,

      reportsVisible,
      reportList,
    };
  },
};
</script>
