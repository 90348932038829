import { reactive } from "vue"
import store from "."
import { Create, Update, Delete, setImages, auth } from "./firebaseInit"

export const table = reactive({
    setImages,
    search: "",
    selectedMenu: "Users",

    showLocation:false,
    locationData:"Users",

    selection: [],
    rejectData: {
        show: false,
        data: [],
        status: "",
        path: "",
    },
    loggedUser:false,
    menuItems: [
        {
            label: "Users",
            icon: "pi pi-users",
            command: () => {
                table.selectedMenu = "Users"
            },
        },
        {
            label: "Clients",
            icon: "pi pi-users",
            command: () => {
                table.selectedMenu = "Clients"
            },
        },
        {
            label: "Tags",
            icon: "pi pi-tags",
            command: () => {
                table.selectedMenu = "Tags"
            },
        },
        {
            label: "Tasks",
            icon: "pi pi-calendar",
            command: () => {
                table.selectedMenu = "Tasks"
            },
        },
        // {
        //     label: "Cases",
        //     icon: "pi pi-briefcase",
        //     command: () => {
        //         table.selectedMenu = "Cases"
        //     },
        // },
        // {
        //     label: "Timesheet",
        //     icon: "pi pi-clock",
        //     command: () => {
        //         table.selectedMenu = "Timesheet"
        //     },
        // },
        // {
        //     label: "Expenses",
        //     icon: "pi pi-wallet",
        //     command: () => {
        //         table.selectedMenu = "Expenses"
        //     },
        // },
        {
            label: "Reports",
            icon: "pi pi-book",
            command: () => {
                table.selectedMenu = "Reports"
            },
        },
        {
            label: "Targets",
            icon: "pi pi-user",
            command: () => {
                table.selectedMenu = "Targets"
            },
        },
        {
            label: "Logout",
            icon: "pi pi-sign-out",
            command: () => {
                auth.signOut();
            },
        },
    ],
    toast: {
        show: false,
        severity: "info",
        summary: "Title",
        detail: "Message Content",
        life: 3000,
    },
    id(data, path) {
        if (path == "Users" || path == "Clients") return data.username;
        else return data.id;
    },
    createDoc(data, path = this.selectedMenu) {
        data.updated = new Date().getTime()
        data.time = new Date().getTime()
        Create(path, table.id(data, path), data).then(() => {
            table.toast.show = true
            table.toast.severity = "success"
            table.toast.summary = "Created"
            table.toast.detail = "Document created successfully. \tId:\t" + table.id(data, path)
        })
            .catch((error) => {
                table.toast.show = true
                table.toast.severity = "error"
                table.toast.summary = "Not Created"
                table.toast.detail = "Error while creating Document!!  \tId:\t" + table.id(data, path) + "\t\t" + error
            });
    },
    updateDoc(data, path = this.selectedMenu) {
        if (typeof (data[0]) == "object") data = data[0]
        delete data.time
        delete data.updated
        data.updated = new Date().getTime()
        Update(path, table.id(data, path), data).then(() => {
            table.toast.show = true
            table.toast.severity = "success"
            table.toast.summary = "Updated"
            table.toast.detail = "Document updated successfully. \tId:\t" + table.id(data, path)
        })
            .catch((error) => {
                table.toast.show = true
                table.toast.severity = "error"
                table.toast.summary = "Not Updated"
                table.toast.detail = "Error while updating Document!!  \tId:\t" + table.id(data, path) + "\t\t" + error
            });
    },
    deleteDoc(data, path = this.selectedMenu) {
        let list = []
        // if only one element in form of object
        if (typeof (data[0]) == "undefined") {
            list = [data]
        } else list = data

        list.forEach(element => {
            Update(path, table.id(element, path), { status: "Deleted", updated: new Date().getTime() }).then(() => {
                table.toast.show = true
                table.toast.severity = "success"
                table.toast.summary = "Deleted"
                table.toast.detail = "Document deleted successfully. \tId:\t" + table.id(element, path)
            })
                .catch((error) => {
                    table.toast.show = true
                    table.toast.severity = "error"
                    table.toast.summary = "Not Deleted"
                    table.toast.detail = "Error while deleating Document!!  \tId:\t" + table.id(element, path) + "\t\t" + error
                });
        });
    },
    deleteDocPermanently(data, path = this.selectedMenu) {
        let list = []
        // if only one element in form of object
        if (typeof (data[0]) == "undefined") {
            list = [data]
        } else list = data

        list.forEach(element => {
            Delete(path, table.id(element, path)).then(() => {
                table.toast.show = true
                table.toast.severity = "success"
                table.toast.summary = "Deleted"
                table.toast.detail = "Document deleted Permanently. \tId:\t" + table.id(element, path)
            }).catch((error) => {
                table.toast.show = true
                table.toast.severity = "error"
                table.toast.summary = "Not Deleted"
                table.toast.detail = "Error while deleating Document!!  \tId:\t" + table.id(element, path) + "\t\t" + error
            });
        });
    },
    sendNotification(username, title, body) {
        let user = store.user.byUsername(username)[0];
        if (user.nid) store.sendNotification(title, body, user.nid);
    },

    stausDoc(data, status, path = this.selectedMenu, other = []) {
        let list = []
        // if only one element in form of object
        if (typeof (data[0]) == "undefined") {
            list = [data]
        } else list = data

        list.forEach(element => {
            let updateDetails = { status: status, updated: new Date().getTime() }
            other.forEach(doc => {
                updateDetails[doc.id] = doc.data
            });
            Update(path, table.id(element, path), updateDetails).then(() => {
                table.toast.show = true
                table.toast.severity = "success"
                table.toast.summary = "Updated"
                table.toast.detail = "Document Updated successfully. \tId:\t" + table.id(element, path)
            })
                .catch((error) => {
                    table.toast.show = true
                    table.toast.severity = "error"
                    table.toast.summary = "Not Updated"
                    table.toast.detail = "Error while updating Document!!  \tId:\t" + table.id(element, path) + "\t\t" + error
                });
        });
    },
})