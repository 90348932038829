import { reactive } from "vue"
import { Reports } from "./firebaseInit"
import store from "./index"

export const reports = reactive({
    list: [],
    data: {},

    create: false,
    update: false,

    splitBtn: [
        {
            label: "Approve",
            icon: "pi pi-check",
            command: () => {
                store.table.stausDoc(store.table.selection, "Approved")
                store.table.selection = []
            },
        },
        {
            label: "Reject",
            icon: "pi pi-times",
            command: () => {
                store.table.stausDoc(store.table.selection, "Rejected")
                store.table.selection = []
            },
        },
        {
            label: "Finish",
            icon: "pi pi-save",
            command: () => {
                store.table.stausDoc(store.table.selection, "Finished")
                store.table.selection = []
            },
        },
    ],
    columns: [
        { field: 'agent_', header: 'Agent', style: { 'min-width': '120px', 'max-width': '150px' } },
        { field: 'case_', header: 'Case', style: { 'min-width': '150px' } },
        { field: 'tag_', header: 'Tag', style: { 'min-width': '150px' } },
        { field: 'details', header: 'Details', style: { 'min-width': '200px' } },
        { field: 'status', header: 'Status', style: { 'min-width': '90px', 'max-width': '100px' } },
    ],
    init() {
        return {
            id: "",
            caseId: "",
            tagId: "",
            details: "",
            username: "",
            audios: "",
            videos: "",

            status: "Submitted",
            title: "",
            time: "",
            updated: "",
        }
    },
    createDoc() {
        this.update = false
        this.create = false
        this.create = true
        this.data = this.init()
    },
    updateDoc(data) {
        this.create = false
        this.update = false
        this.update = true
        this.data = data
    },
    async export(caseData) {
        let client = store.client.byUsername(caseData.clientId)[0];

        let reportData = {
            ClientName: client.name,
            CaseLocation: caseData.location,
            TargetNames: [],
            TargetPhoneNumbers: [],
            TargetCompanyNames: [],
            TargetEmails: [],
            TargetAddresses: [],
            TargetWebsites: [],
        };

        caseData.targets.forEach((id) => {
            let target = store.targets.byId(id)[0];
            if (target) {
                reportData.TargetNames = reportData.TargetNames.concat(target.name);
                reportData.TargetPhoneNumbers = reportData.TargetPhoneNumbers.concat(
                    target.phone
                );
                reportData.TargetCompanyNames = reportData.TargetCompanyNames.concat(
                    target.company
                );
                reportData.TargetEmails = reportData.TargetEmails.concat(
                    target.email
                );
                reportData.TargetAddresses = reportData.TargetAddresses.concat(
                    target.address
                );
                reportData.TargetWebsites = reportData.TargetWebsites.concat(
                    target.website
                );
            }
        });

        let reportEntris = [];
        let reportList = store.reports.byCase(caseData.id)
        if (reportList) for (let i = 0; i < reportList.length; i++) {
            let report = reportList[i]
            let images = await store.getImgs("Reports", report.id)
            report.images = images;
            console.log("report.images", report.images)
            reportEntris.push(report);
        }

        store.exportReport(
            reportData,
            reportEntris,
            store.tags.list.filter((data) => data.type.includes("Reports"))
        );

    },
    close() {
        this.create = false;
        this.update = false;
        this.data = this.init()
    },
    byId(id) {
        return this.list.filter(
            (data) => data.id.toLowerCase() == id.toLowerCase()
        )
    },
    byCase(caseId) {
        return this.list.filter(
            (data) => data.caseId.toLowerCase() == caseId.toLowerCase()
        )
    },
    sync() {
        return Reports.where('status', 'not-in', ['Deleted',]).orderBy("status").orderBy("updated", "desc").onSnapshot({ includeMetadataChanges: true }, (snapshot) => {
            this.list = []
            snapshot.forEach((doc) => {
                let data = doc.data()
                data.agent_ = (store.user.byUsername(data.username)[0])?store.user.byUsername(data.username)[0].name : "Deleted"
                data.case_ = (store.cases.byId(data.caseId)[0])?store.cases.byId(data.caseId)[0].name : "Deleted"
                if (store.tags.byId(data.tagId)[0]) data.tag_ = store.tags.byId(data.tagId)[0].tag
                this.list.push(data)
            });
        }, (error) => { console.error("Reports error", error) })
    },
})