<template>
  <Toast />
  <div class="p-fluid p-formgrid p-grid p-shadow-1 p-m-6 p-p-6 p-jc-center">
    <div style="width: fit-content" class="p-field p-col-12">
      <h2>Welcome to True Buddy Consulting Pvt. Ltd.</h2>
    </div>

    <div v-if="!otpClk" class="p-field p-col-12">
      <label for="username">Username</label>
      <InputText
        id="username"
        v-model="formData.username"
        placeholder="Username"
        type="text"
      />
      <small class="p-error" v-if="formData.username.length == 0">
        Username is required.
      </small>
    </div>

    <div
      v-show="!otpClk"
      id="recaptcha-container"
      style="width: fit-content"
      class="p-field p-col-12"
    />

    <div v-if="!otpClk" class="p-field p-col-12">
      <Button
        :disabled="formData.username.length < 3"
        label="Request Otp"
        icon="pi pi-key"
        class="p-button-success"
        @click="otp"
        autofocus
      />
    </div>

    <div v-if="otpClk" class="p-field p-col-12">
      <label for="otp">OTP (One Time Password)</label>
      <InputMask
        id="otp"
        mask="999 999"
        v-model="formData.otp"
        placeholder="000 000"
      />
      <small class="p-error" v-if="formData.otp.length == 0">
        OTP is required.
      </small>
    </div>

    <div v-if="formData.otp.length > 0" class="p-field p-col-12">
      <Button
        label="Login"
        icon="pi pi-sign-in"
        class="p-button"
        @click="login"
        autofocus
      />
    </div>
  </div>
</template>

<script>
import { inject, ref } from "vue";
import { useToast } from "primevue/usetoast";
export default {
  setup() {
    const store = inject("store");
    const toast = useToast();
    const toastChk = ref(false);
    const src = store.login;
    const otpClk = ref(false);
    const recaptchaVerifier = ref();
    const confirmationResult = ref();
    const formData = ref({
      username: "",
      otp: "",
    });
    const userData = ref();

    function showToast(severity, summary, detail) {
      toastChk.value = true;
      if (toastChk.value) {
        toast.add({
          severity: severity,
          summary: summary,
          detail: detail,
          life: 3000,
        });
        toastChk.value = false;
      }
    }

    const otp = () => {
      src.auth.signOut();
      if (formData.value.username.length > 3) {
        src.getUser(
          formData.value.username,
          (data) => {
            userData.value = data;
            if (data.status == "Active")
              if (
                data.role == "Admin" ||
                data.role == "Accounts" ||
                data.role == "Manager"
              ) {
                recaptchaVerifier.value = new src.authObj.RecaptchaVerifier(
                  "recaptcha-container"
                );

                src.auth
                  .signInWithPhoneNumber(
                    "+91" + data.phone,
                    recaptchaVerifier.value
                  )
                  .then((result) => {
                    showToast(
                      "success",
                      "OTP Sent Sucessfully!!",
                      "OTP sent to yor ragisterd phone number\t+91" + data.phone
                    );
                    otpClk.value = true;
                    confirmationResult.value = result;
                  })
                  .catch((error) => {
                    showToast(
                      "error",
                      "OTP Not Sent",
                      "Something went wrong!!\t" + error.message
                    );
                    console.log("otp", error);
                  });
              } else
                showToast(
                  "warn",
                  "Unauthorized Access!!",
                  "You are not authorized to access this section, please connect with admin."
                );
            else
              showToast(
                "warn",
                "This user is not Active!!",
                "You are not an active, please connect with admin."
              );
          },
          () => {
            showToast(
              "warn",
              "Username not found!!",
              "No user ragisterd with this username."
            );
          },
          (error) => {
            showToast("error", "Something went wrong!!", error.message);
            console.log("otp", error);
          }
        );
      }
    };
    const login = () => {
      let otp = formData.value.otp.replace(/\s/g, "").toString();
      confirmationResult.value
        .confirm(otp)
        .then((result) => {
          console.log("result", result);
          src.auth.onAuthStateChanged(function (user) {
            if (!user.displayName)
              user.updateProfile({
                displayName: userData.value.username,
              });
          });
          showToast(
            "success",
            "Login Sucessfully!!",
            "Login is sucessfull, Welcome to TBCPL"
          );
        })
        .catch((error) => {
          showToast("error", "Invalid Otp" + otp, error.message);
        });
    };

    return {
      store,
      otp,
      otpClk,
      login,
      recaptchaVerifier,
      confirmationResult,

      src,
      formData,
    };
  },
};
</script>
