<template>
  <Dialog
    
    v-model:visible="visible"
    :style="{ width: '98vw' }"
    :maximizable="true"
    :modal="true"
    :closable="false"
  >
    <div id="map" h></div>

    <template #footer>
      <!-- <Button
        label="Export"
        icon="pi pi-upload"
        @click="exportIt"
        class="p-button-raised"
      /> -->
      <Button
        label="Close"
        icon="pi pi-times"
        @click="close"
        class="p-button-text p-mt-2"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import { inject, ref, watch } from "vue";
export default {
  setup() {
    const store = inject("store");
    let src = store.table;
    const visible = ref(false);

    const close = () => {
      src.showLocation = true;
      visible.value = false
      src.showLocation = false;
    };

    const map = ref();

    // Watcher
    watch([src, store.user, store.cases], () => {
      visible.value = src.showLocation;
      if (visible.value) {
        store.mapApi.load().then(() => {
          const geocoder = new google.maps.Geocoder();
          map.value = new google.maps.Map(document.getElementById("map"), {
            center: { lat: 20, lng: 77 },
            zoom: 5,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
          });

          const infoWindow = new google.maps.InfoWindow();

          try {
            if (src.locationData == "Users") {
              store.user.list.forEach((user) => {
                if (user.location && user.location[0]) {
                  let locationData = user.location[0];
                  const marker = new google.maps.Marker({
                    position: {
                      lat: locationData.Latitude,
                      lng: locationData.Longitude,
                    },
                    animation: google.maps.Animation.DROP,
                    title: user.name + "<br>" + locationData.address,
                    label: user.name,
                    map: map.value,
                    optimized: false,
                  });
                  // Add a click listener for each marker, and set up the info window.
                  marker.addListener("click", () => {
                    infoWindow.close();
                    infoWindow.setContent(marker.getTitle());
                    infoWindow.open(marker.getMap(), marker);
                  });
                }
              });
            } else if (src.locationData == "Cases") {
              store.cases.list.forEach((caseData) => {
                if (
                  caseData.status == "Investigation Ongoing" ||
                  caseData.status == "Investigation Completed" ||
                  caseData.status == "Report Sent" ||
                  caseData.status == "Invoicing Done"
                ) {
                  let state = store.states.find((data) => {
                    return caseData.state == data.name;
                  });
                  if (state && geocoder) {
                    geocoder.geocode(
                      {
                        address:
                          caseData.location +
                          " , " +
                          caseData.state +
                          " , India",
                      },
                      (results, status) => {
                        let location = {
                          lat: state.lat,
                          lng: state.long,
                        };
                        if (status == google.maps.GeocoderStatus.OK)
                          location = results[0].geometry.location;
                        else
                          location = {
                            lat: state.lat,
                            lng: state.long,
                          };

                        let title =
                          caseData.name +
                          "<br>Location : " +
                          caseData.location +
                          " , " +
                          state.name +
                          "<br>Product : " +
                          caseData.product +
                          "<br>Client : " +
                          store.client.list.find((data) => {
                            return caseData.clientId == data.username;
                          }).name +
                          "<br>Status : " +
                          caseData.status;

                        let iconColor = "Red";
                        switch (caseData.status) {
                          case "Investigation Ongoing":
                            iconColor = "Gold";
                            break;
                          case "Investigation Completed":
                            iconColor = "Lime";
                            break;
                          case "Report Sent":
                            iconColor = "DarkGreen";
                            break;
                          case "Invoicing Done":
                            iconColor = "DeepSkyBlue";
                            break;
                          default:
                            iconColor = "Red";
                            break;
                        }

                        const marker = new google.maps.Marker({
                          position: location,
                          icon: {
                            path: "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
                            fillColor: iconColor,
                            fillOpacity: 1,
                            anchor: new google.maps.Point(0, 0),
                            strokeWeight: 1,
                            scale: 1.5,
                          },
                          animation: google.maps.Animation.DROP,
                          title: title,
                          map: map.value,
                          optimized: false,
                        });

                        marker.addListener("click", () => {
                          infoWindow.close();
                          infoWindow.setContent(marker.getTitle());
                          infoWindow.open(marker.getMap(), marker);
                        });
                      }
                    );
                  }
                }
              });
            }
          } catch (error) {
            console.error(error);
          }
        });
      }
    });

    return {
      src,
      map,
      store,
      visible,

      close,
    };
  },
};
</script>

<style scoped>
#map {
  height: 90vh;
  /* The height is 400 pixels */
  width: 100%;
  /* The width is the width of the web page */
}
</style>
