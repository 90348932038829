import { reactive } from "vue"
import { Tasks } from "./firebaseInit"
import store from "./index"

export const tasks = reactive({
    list: [],
    data: {},
    create: false,
    update: false,
    splitBtn: [
        {
            label: "Approve",
            icon: "pi pi-check",
            command: () => {
                store.table.stausDoc(store.table.selection, "Approved")
                store.table.selection = []
            },
        },
        {
            label: "Reject",
            icon: "pi pi-times",
            command: () => {
                store.table.stausDoc(store.table.selection, "Rejected")
                store.table.selection = []
            },
        },
        {
            label: "Finish",
            icon: "pi pi-save",
            command: () => {
                store.table.stausDoc(store.table.selection, "Finished")
                store.table.selection = []
            },
        },
    ],
    columns: [
        { field: 'startTime_', header: 'Date & Time', style: { 'min-width': '120px', 'max-width': '200px' } },
        { field: 'case', header: 'Case', style: { 'min-width': '230px', 'max-width': '270px' } },
        { field: 'agents_', header: 'Agent', style: { 'min-width': '100px' } },
        { field: 'tagList', header: 'Tag', style: { 'min-width': '100px' } },
        { field: 'status', header: 'Status', style: { 'min-width': '90px', 'max-width': '100px' } },
    ],
    init() {
        return {
            id: "TSK" + new Date().getTime() + Math.floor(Math.random() * 100),
            username: [],
            caseId: "",
            tagId: "",
            subtask: [],

            startTime: new Date(),

            status: "Assigned",
            rank: 1,
            time: "",
            updated: "",
        }
    },
    createDoc(caseId="") {
        this.update = false
        this.create = false
        this.create = true
        this.data = this.init()
        this.data.caseId = caseId
    },
    updateDoc(data) {
        this.create = false
        this.update = false
        this.update = true
        this.data = data
    },
    close() {
        this.create = false;
        this.update = false;
        this.data = this.init()
    },
    byId(id) {
        return this.list.filter(
            (data) => data.id.toLowerCase() == id.toLowerCase()
        )
    },
    sync() {
        return Tasks.where('status', 'not-in', ['Deleted',]).orderBy("status").orderBy("updated", "desc").onSnapshot({ includeMetadataChanges: true }, (snapshot) => {
            this.list = []
            snapshot.forEach((doc) => {
                let data = doc.data()
                data.agents_ = []
                data.tagList = store.tags.byId(data.tagId)[0].tag
                data.case = store.cases.byId(data.caseId)[0].name
                data.startTime_ = store.date.toDateTime(data.startTime)
                data.username.forEach(element => {
                    data.agents_.push((store.user.byUsername(element)[0])?store.user.byUsername(element)[0].name : "Deleted")
                });
                data.agents_ = data.agents_.join(" , ")
                this.list.push(data)
            });
        }, (error) => { console.error("Tasks error", error) })
    },
})