<template>
  <Dialog
    v-model:visible="visible"
    :style="{ width: '98vw' }"
    :maximizable="true"
    :modal="true"
    :closable="false"
  >
    <div id="map" h></div>

    <template #footer>
      <Button
        label="Close"
        icon="pi pi-times"
        @click="close"
        class="p-button-text p-mt-2"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import { inject, ref, watch } from "vue";
export default {
  setup() {
    const store = inject("store");
    let src = store.locations;
    const visible = ref(false);

    const close = () => {
      src.showMap = true;
      visible.value = false;
      src.showMap = false;
    };

    const map = ref();
    const locations = ref();
    const origin = ref();
    const destination = ref();

    // Watcher
    watch([src], () => {
      visible.value = src.showMap;
      if (visible.value) {
        locations.value = [];
        let lastTime = 0;
        src.list
          .sort((a, b) => {
            if (a.time < b.time) return -1;
            if (a.time > b.time) return 1;
            return 0;
          })
          .forEach((data) => {
            if (lastTime == 0 || parseInt(data.time / 60000) - lastTime > 5) {
              locations.value.push(data);
              lastTime = parseInt(data.time / 60000);
            }
          });

        if (locations.value.length >= 25)
          locations.value = locations.value.slice(0, 25);

        if (locations.value.length > 0) {
          origin.value = {
            lat: locations.value[0].Latitude,
            lng: locations.value[0].Longitude,
          };

          destination.value = {
            lat: locations.value[locations.value.length - 1].Latitude,
            lng: locations.value[locations.value.length - 1].Longitude,
          };

          store.mapApi.load().then(() => {
            const directionsService = new google.maps.DirectionsService();
            const directionsRenderer = new google.maps.DirectionsRenderer();

            let waypts = [];
            if (locations.value.length > 2) {
              waypts = locations.value.map((data) => ({
                location: {
                  lat: data.Latitude,
                  lng: data.Longitude,
                },
                stopover: true,
              }));
              waypts = waypts.slice(1, waypts.length - 1);
              waypts = waypts.slice(0, waypts.length - 2);
            }

            map.value = new google.maps.Map(document.getElementById("map"), {
              center: { lat: 20, lng: 77 },
              zoom: 5,
              mapTypeId: google.maps.MapTypeId.ROADMAP,
            });
            directionsRenderer.setMap(map.value);

            try {
              directionsService
                .route({
                  origin: origin.value,
                  destination: destination.value,
                  waypoints: waypts,
                  optimizeWaypoints: true,
                  travelMode: google.maps.TravelMode.DRIVING,
                })
                .then((response) => {
                  directionsRenderer.setDirections(response);
                })
                .catch((e) =>
                  window.alert("Directions request failed due to " + e)
                );
            } catch (error) {
              console.error(error);
            }
          });
        } else {
          alert("No location found");
          close();
        }
      }
    });

    return {
      src,
      map,
      store,
      visible,

      close,
    };
  },
};
</script>

<style scoped>
#map {
  height: 90vh;
  /* The height is 400 pixels */
  width: 100%;
  /* The width is the width of the web page */
}
</style>
