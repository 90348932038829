import { reactive } from "vue"
import { Tags } from "./firebaseInit"
import store from "./index"

export const tags = reactive({
    list: [],
    data: {},
    create: false,
    update: false,
    splitBtn: [
        {
            label: "Activate",
            icon: "pi pi-check",
            command: () => {
                store.table.stausDoc(store.table.selection, "Active")
                store.table.selection = []
            },
        },
        {
            label: "Deactivate",
            icon: "pi pi-ban",
            command: () => {
                store.table.stausDoc(store.table.selection, "Inactive")
                store.table.selection = []
            },
        },
    ],
    columns: [
        { field: 'id', header: 'Id', style: { 'min-width': '150px', 'max-width': '200px' } },
        { field: 'tag', header: 'Tag', style: { 'min-width': '150px' } },
        { field: 'typeList', header: 'Type', style: { 'min-width': '150px' } },
        { field: 'cap', header: 'Cap', style: { 'min-width': '80px', 'max-width': '100px' } },
        { field: 'status', header: 'Status', style: { 'min-width': '90px', 'max-width': '100px' } },
    ],
    init() {
        return {
            id: "",
            tag: "",
            details: "",
            type: "",
            cap: 0,

            status: "Active",
            time: "",
            updated: "",
        }
    },
    createDoc() {
        this.update = false
        this.create = false
        this.create = true
        this.data = this.init()
    },
    updateDoc(data) {
        this.create = false
        this.update = false
        this.update = true
        this.data = data
    },
    close() {
        this.create = false;
        this.update = false;
        this.data = this.init()
    },
    byId(id) {
        return this.list.filter(
            (data) => data.id.toLowerCase() == id.toLowerCase()
        )
    },
    sync() {
        return Tags.where('status', 'not-in', ['Deleted',]).orderBy("status").orderBy("updated", "desc").onSnapshot({ includeMetadataChanges: true }, (snapshot) => {
            this.list = []
            snapshot.forEach((doc) => {
                let data = doc.data()
                data.typeList = data.type.join(" , ")
                this.list.push(data)
            });
        }, (error) => { console.error("Tags error", error) })
    },
})