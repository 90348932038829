<template>
  <Dialog
    :header="title"
    v-model:visible="visible"
    :style="{ width: '90vw' }"
    :maximizable="true"
    :modal="true"
    :closable="false"
  >
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="name">Full Name</label>
        <InputText
          v-model="formData.name"
          id="name"
          placeholder="Full Name"
          type="text"
        />
        <small class="p-error" v-if="formData.name.length == 0">
          Name is required.
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="username">Username</label>
        <InputText
          id="username"
          :disabled="src.update"
          v-model="formData.username"
          placeholder="Username"
          type="text"
        />
        <small class="p-error" v-if="formData.username.length == 0">
          Username is required.
        </small>
        <small class="p-error" v-if="src.create && usernameExist()">
          Username is used before try with diffrent Username!
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="phone">Phone Number</label>
        <InputMask
          id="phone"
          mask="999 999 9999"
          v-model="formData.phone"
          placeholder="000 000 0000"
        />
        <small class="p-error" v-if="formData.phone.length == 0">
          Phone Number is required.
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="role">Role</label>
        <Dropdown
          inputId="role"
          id="role"
          :disabled="store.table.loggedUser.role != 'Admin' && src.update"
          v-model="formData.role"
          :options="roles"
          optionLabel="name"
          optionValue="name"
          placeholder="Select Role"
        />
        <small class="p-error" v-if="formData.role.length == 0">
          Role is required.
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="dob">Date of Birth</label>
        <Calendar
          id="dob"
          v-model="formData.dob"
          :maxDate="maxDate"
          :manualInput="false"
          :monthNavigator="true"
          :yearNavigator="true"
          :yearRange="yearRange"
          placeholder="MM/DD/YYYY"
        />
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="status">Status</label>
        <Dropdown
          id="status"
          inputId="status"
          :disabled="store.table.loggedUser.role != 'Admin' && src.update"
          v-model="formData.status"
          :options="status"
          optionLabel="name"
          optionValue="name"
          placeholder="Select status"
        />
        <small class="p-error" v-if="formData.status.length == 0">
          Status is required.
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="email">Email</label>
        <InputText
          id="email"
          v-model="formData.email"
          placeholder="Email Google"
          type="text"
        />
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="cases">Cases</label>
        <MultiSelect
          id="cases"
          v-model="formData.cases"
          :filter="true"
          :options="cases"
          optionLabel="name"
          optionValue="id"
          placeholder="Select Cases"
          display="chip"
        />
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="address">Address</label>
        <Textarea
          id="address"
          rows="1"
          v-model="formData.address"
          :autoResize="true"
          placeholder="Address"
        />
      </div>

      <div v-if="src.update" class="p-field p-col-12">
        <div @click="detailsShow = !detailsShow" class="p-shadow-3 p-p-2">
          <h4 :style="{ textAlign: 'center' }" class="p-m-0 p-p-0">
            Total Paid By Accounts &#8377; {{ expenceCalc.p }} - Total Approved
            By Accounts &#8377; {{ advanceCalc.aa }} = Total Balance &#8377;
            {{ expenceCalc.p - expenceCalc.aa }}
          </h4>
        </div>
      </div>
    </div>

    <div v-if="detailsShow && src.update" class="p-fluid p-formgrid p-grid">
      <div class="p-col-12 p-md-6">
        <div class="p-shadow-3 p-p-2">
          <h4 :style="{ textAlign: 'center' }" class="p-m-0 p-p-0">
            Advance ( {{ advanceCalc.tc }} )
          </h4>
          <div class="p-grid p-jc-between p-m-1">
            <span class="p-col-8 p-mx-1">
              Submitted
              <span :style="{ float: 'right' }"> ({{ advanceCalc.sc }}) </span>
            </span>
            <span class="p-col-3 p-mx-1">&#8377; {{ advanceCalc.s }}</span>
            <span class="p-col-8 p-mx-1">
              Approved by Manager
              <span :style="{ float: 'right' }"> ({{ advanceCalc.amc }}) </span>
            </span>
            <span class="p-col-3 p-mx-1">&#8377; {{ advanceCalc.am }}</span>
            <span class="p-col-8 p-mx-1">
              Rejected by Manager
              <span :style="{ float: 'right' }"> ({{ advanceCalc.rmc }}) </span>
            </span>
            <span class="p-col-3 p-mx-1">&#8377; {{ advanceCalc.rm }}</span>
            <span class="p-col-8 p-mx-1">
              Approved by Accounts
              <span :style="{ float: 'right' }"> ({{ advanceCalc.aac }}) </span>
            </span>
            <span class="p-col-3 p-mx-1">&#8377; {{ advanceCalc.aa }}</span>
            <span class="p-col-8 p-mx-1">
              Rejected by Accounts
              <span :style="{ float: 'right' }"> ({{ advanceCalc.rac }}) </span>
            </span>
            <span class="p-col-3 p-mx-1">&#8377; {{ advanceCalc.ra }}</span>
            <span class="p-col-8 p-mx-1">
              Paid
              <span :style="{ float: 'right' }"> ({{ advanceCalc.pc }}) </span>
            </span>
            <span class="p-col-3 p-mx-1">&#8377; {{ advanceCalc.p }}</span>
            <span class="p-col-8 p-mx-1">
              Total Paid Advance
              <span :style="{ float: 'right' }"> ({{ advanceCalc.pc }}) </span>
            </span>
            <span class="p-col-3 p-mx-1">&#8377; {{ advanceCalc.p }}</span>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-6">
        <div class="p-shadow-3 p-p-2">
          <h4 :style="{ textAlign: 'center' }" class="p-m-0 p-p-0">
            Expenses ( {{ expenceCalc.tc }} )
          </h4>
          <div class="p-grid p-jc-between p-m-1">
            <span class="p-col-8 p-mx-1">
              Submitted
              <span :style="{ float: 'right' }"> ({{ expenceCalc.sc }}) </span>
            </span>
            <span class="p-col-3 p-mx-1">&#8377; {{ expenceCalc.s }}</span>
            <span class="p-col-8 p-mx-1">
              Approved by Manager
              <span :style="{ float: 'right' }"> ({{ expenceCalc.amc }}) </span>
            </span>
            <span class="p-col-3 p-mx-1">&#8377; {{ expenceCalc.am }}</span>
            <span class="p-col-8 p-mx-1">
              Rejected by Manager
              <span :style="{ float: 'right' }"> ({{ expenceCalc.rmc }}) </span>
            </span>
            <span class="p-col-3 p-mx-1">&#8377; {{ expenceCalc.rm }}</span>
            <span class="p-col-8 p-mx-1">
              Approved by Accounts
              <span :style="{ float: 'right' }"> ({{ expenceCalc.aac }}) </span>
            </span>
            <span class="p-col-3 p-mx-1">&#8377; {{ expenceCalc.aa }}</span>
            <span class="p-col-8 p-mx-1">
              Rejected by Accounts
              <span :style="{ float: 'right' }"> ({{ expenceCalc.rac }}) </span>
            </span>
            <span class="p-col-3 p-mx-1">&#8377; {{ expenceCalc.ra }}</span>
            <span class="p-col-8 p-mx-1">
              Paid
              <span :style="{ float: 'right' }"> ({{ expenceCalc.pc }}) </span>
            </span>
            <span class="p-col-3 p-mx-1">&#8377; {{ expenceCalc.p }}</span>
            <span class="p-col-8 p-mx-1"> Total Balance </span>
            <span class="p-col-3 p-mx-1">
              &#8377; {{ expenceCalc.p - expenceCalc.aa }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="src.update" class="p-fluid p-formgrid p-grid p-mt-3">
      <div class="p-col-12 p-md-4">
        <label>UID :</label>
        <small class="p-text-normal p-m-2">
          {{ src.data.uid }}
        </small>
      </div>
      <div class="p-col-12 p-md-4">
        <label>Created :</label>
        <small class="p-text-normal p-m-2">
          {{ store.date.toDateTime(formData.time) }}
        </small>
      </div>
      <div class="p-col-12 p-md-4">
        <label>Last Updated :</label>
        <small class="p-text-normal p-m-2">
          {{ store.date.toDateTime(formData.updated) }}
        </small>
      </div>
    </div>

    <template #footer>
      <Button
        v-if="src.update"
        label="Location"
        icon="pi pi-map-marker"
        class="p-button-info"
        @click="location"
      />
      <Button
        v-if="src.update"
        label="Advance"
        icon="pi pi-wallet"
        class="p-button-info"
        @click="advance"
      />

      <Button
        v-if="src.update"
        label="&#8377; &nbsp; Expenses"
        class="p-button-info"
        @click="expenses"
      />
      <!-- <Button
        v-if="src.update"
        label="Balance"
        icon="pi pi-chart-line"
        class="p-button-info"
        @click="balance"
      /> -->
      <Button
        v-if="src.update"
        label="Timesheet"
        icon="pi pi-clock"
        class="p-button-secondary"
        @click="timesheet"
      />
      <Button
        v-if="src.create"
        label="Create"
        icon="pi pi-check"
        class="p-button-success"
        @click="create"
      />
      <Button
        v-if="src.update"
        label="Update"
        icon="pi pi-check"
        class="p-button-warning"
        @click="update"
      />
      <Button
        v-if="src.update"
        label="Delete"
        icon="pi pi-trash"
        class="p-button-danger"
        @click="dalete"
      />
      <Button
        label="Close"
        icon="pi pi-times"
        @click="close"
        class="p-button-text"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import { inject, ref, watch } from "vue";
export default {
  setup() {
    const store = inject("store");
    let src = store.user;
    const formData = ref({});
    const visible = ref(false);
    const detailsShow = ref(false);
    const title = ref("Create User");
    const roles = ref([
      { name: "Admin" },
      { name: "Accounts" },
      { name: "Agent" },
      { name: "Manager" },
      { name: "Office Staff" },
    ]);
    const cases = ref([]);
    const advanceCalc = ref();
    const expenceCalc = ref();
    const status = ref([
      { name: "Active" },
      { name: "Blocked" },
      { name: "Deleted" },
    ]);
    const today = new Date();
    const maxDate = ref(new Date());

    const yearRange =
      (today.getFullYear() - 60).toString() +
      ":" +
      (today.getFullYear() - 18).toString();

    // functions
    function validate() {
      return (
        formData.value.username.length > 0 &&
        formData.value.phone.length > 0 &&
        formData.value.role.length > 0 &&
        formData.value.name.length > 0 &&
        formData.value.status.length > 0
      );
    }
    function usernameExist() {
      return (
        formData.value.username.length > 0 &&
        src.byUsername(formData.value.username).length > 0
      );
    }
    // Crud
    function setupSubmit() {
      formData.value.dob = new Date(formData.value.dob).getTime();
      formData.value.phone = parseInt(formData.value.phone.replace(/\s/g, ""));
    }

    const create = () => {
      if (validate() && !usernameExist()) {
        setupSubmit();
        store.table.createDoc(formData.value, "Users");
        close();
      }
    };
    const update = () => {
      if (validate()) {
        setupSubmit();
        store.table.updateDoc(formData.value, "Users");
        close();
      }
    };
    const dalete = () => {
      store.table.deleteDoc(src.data, "Users");
      close();
    };
    const close = () => {
      formData.value = src.init();
      src.close();
    };
    // Actions
    const advance = () => {
      store.advances.selected = formData.value.username;
      store.advances.selectedType = "user";
      store.advances.show = true;
    };
    const expenses = () => {
      store.expenses.selected = formData.value.username;
      store.expenses.selectedType = "user";
      store.expenses.show = true;
    };
    const timesheet = () => {
      store.timesheet.selected = formData.value.username;
      store.timesheet.selectedType = "user";
      store.timesheet.show = true;
    };
    const balance = () => {
      alert("work in progress");
    };
    const location = () => {
      store.locations.open(formData.value.username);
    };

    // Watcher
    watch(
      [store.table, src, store.cases, store.expenses, store.advances],
      () => {
        formData.value = src.init();
        visible.value = src.create || src.update;
        if (src.create || src.update) {
          maxDate.value.setFullYear(
            today.getFullYear() - 18,
            today.getMonth(),
            today.getDate()
          );
          let caseList = [];
          store.cases.list.forEach((element) => {
            caseList.push({ name: element.name, id: element.id });
          });
          cases.value = caseList;
        }
        if (src.create) {
          title.value = "Create User";
          formData.value.dob = maxDate.value;
        }
        if (src.update) {
          title.value = "Update User";
          formData.value.username = src.data.username;
          formData.value.phone =
            src.data.phone.toString().substr(0, 3) +
            " " +
            src.data.phone.toString().substr(3, 3) +
            " " +
            src.data.phone.toString().substr(6);
          formData.value.role = src.data.role;
          formData.value.name = src.data.name;
          formData.value.status = src.data.status;
          formData.value.email = src.data.email;
          let dobVal = ref(new Date());
          dobVal.value.setTime(src.data.dob);
          formData.value.dob = dobVal.value;
          formData.value.address = src.data.address;
          formData.value.cases = src.data.cases;
          formData.value.time = src.data.time;
          formData.value.updated = src.data.updated;

          expenceCalc.value = {
            t: 0,
            s: 0,
            am: 0,
            rm: 0,
            aa: 0,
            ra: 0,
            p: 0,
            tc: 0,
            sc: 0,
            amc: 0,
            rmc: 0,
            aac: 0,
            rac: 0,
            pc: 0,
          };
          store.expenses.list.forEach((element) => {
            if (src.data.username == element.username) {
              expenceCalc.value.t += element.amount;
              expenceCalc.value.tc++;
              switch (element.status) {
                case "Submitted":
                  expenceCalc.value.s += element.amount;
                  expenceCalc.value.sc++;
                  break;
                case "Approved by Manager":
                  expenceCalc.value.am += element.amount;
                  expenceCalc.value.amc++;
                  break;
                case "Rejected by Manager":
                  expenceCalc.value.rm += element.amount;
                  expenceCalc.value.rmc++;
                  break;
                case "Approved by Accounts":
                  expenceCalc.value.aa += element.amount;
                  expenceCalc.value.aac++;
                  break;
                case "Rejected by Accounts":
                  expenceCalc.value.ra += element.amount;
                  expenceCalc.value.rac++;
                  break;
                case "Paid":
                  expenceCalc.value.p += element.amount;
                  expenceCalc.value.pc++;
                  break;
              }
            }
          });

          advanceCalc.value = {
            t: 0,
            s: 0,
            am: 0,
            rm: 0,
            aa: 0,
            ra: 0,
            p: 0,
            tc: 0,
            sc: 0,
            amc: 0,
            rmc: 0,
            aac: 0,
            rac: 0,
            pc: 0,
          };

          store.advances.list.forEach((element) => {
            if (src.data.username == element.username) {
              advanceCalc.value.t += element.amount;
              advanceCalc.value.tc++;
              switch (element.status) {
                case "Submitted":
                  advanceCalc.value.s += element.amount;
                  advanceCalc.value.sc++;
                  break;
                case "Approved by Manager":
                  advanceCalc.value.am += element.amount;
                  advanceCalc.value.amc++;
                  break;
                case "Rejected by Manager":
                  advanceCalc.value.rm += element.amount;
                  advanceCalc.value.rmc++;
                  break;
                case "Approved by Accounts":
                  advanceCalc.value.aa += element.amount;
                  advanceCalc.value.aac++;
                  break;
                case "Rejected by Accounts":
                  advanceCalc.value.ra += element.amount;
                  advanceCalc.value.rac++;
                  break;
                case "Paid":
                  advanceCalc.value.p += element.amount;
                  advanceCalc.value.pc++;
                  break;
              }
            }
          });
        }
      }
    );

    return {
      store,

      src,
      visible,
      formData,
      detailsShow,

      create,
      update,
      close,
      dalete,

      title,
      roles,
      cases,
      status,
      maxDate,
      yearRange,

      validate,
      usernameExist,

      advance,
      expenses,
      balance,
      timesheet,
      location,

      expenceCalc,
      advanceCalc,
    };
  },
};
</script>
