<template>
  <Dialog
    :header="title"
    v-model:visible="visible"
    :style="{ width: '98vw' }"
    :maximizable="true"
    :modal="true"
    :closable="false"
  >
    <DataTable
      showGridlines
      ref="dataTableRef"
      class="p-datatable-sm"
      selectionMode="multiple"
      responsiveLayout="scroll"
      v-model:selection="src.selection"
      :rows="10"
      :paginator="true"
      scrollHeight="flex"
      :scrollable="true"
      :value="tableData"
      dataKey="id"
      :metaKeySelection="false"
      :rowsPerPageOptions="[5, 10, 20, 50, 100]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    >
      <template #empty> No data found. </template>
      <template #loading> Loading data. Please wait. </template>

      <Column
        :exportable="false"
        selectionMode="multiple"
        style="max-width: 37px"
        frozen
      />

      <Column
        v-for="col of tableColumns"
        :field="col.field"
        :header="col.header"
        :key="col.field"
        :sortable="true"
        :scrollable="true"
        :style="col.style"
      />

      <Column header="Actions" :exportable="false" style="max-width: 100px">
        <template #body="slotProps">
          <div class="p-d-flex">
            <Button
              icon="pi pi-pencil"
              class="p-button-sm p-button-raised p-button-rounded p-mr-2"
              @click="src.updateDoc(slotProps.data)"
            />
            <Button
              icon="pi pi-trash"
              class="
                p-button-sm p-button-raised p-button-rounded p-button-danger
              "
              @click="store.table.deleteDoc(slotProps.data, 'Expenses')"
            />
          </div>
        </template>
      </Column>
    </DataTable>

    <template #footer>
      <Button
        label="Export"
        icon="pi pi-upload"
        @click="exportExpenses"
        class="p-button-raised"
      />
      <Button
        label="&#8377; &nbsp; New Expance"
        @click="createNew"
        class="p-button-raised p-button-info"
      />
      <Button
        label="Close"
        icon="pi pi-times"
        @click="close"
        class="p-button-text p-mt-2"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import { inject, ref, watch } from "vue";
export default {
  setup() {
    const store = inject("store");
    let src = store.expenses;
    const visible = ref(false);
    const title = ref();

    const dataTableRef = ref();
    const tableColumns = ref();
    const tableData = ref();

    const close = () => {
      src.show = true;
      src.show = false;
    };
    const createNew = () => {
      src.createDoc();
    };

    const exportExpenses = () => {
      if (src.selection.length > 0) {
        src.exportIt(src.selection, src.selected);
      } else
        src.exportIt(
          tableData.value.sort((a, b) => (a.caseId > b.caseId ? 1 : -1)),
          src.selected
        );
    };

    // Watcher
    watch([src], () => {
      visible.value = src.show;

      if (visible.value) {
        tableColumns.value = src.columns;
        switch (src.selectedType) {
          case "user":
            let user = store.user.byUsername(src.selected)[0];
            if (user) {
              title.value = "Expenses of " + user.name;
              tableData.value = src.list
                .filter((data) => data.username == user.username)
                .sort((a, b) => (a.caseId > b.caseId ? 1 : -1));
            } else visible.value = false;
            break;
          case "cases":
            let cases = store.cases.byId(src.selected)[0];
            if (cases) {
              title.value = "Expenses of " + cases.name;
              tableData.value = src.list
                .filter((data) => data.caseId == cases.id)
                .sort((a, b) => (a.username > b.username ? 1 : -1));
            } else visible.value = false;
            break;
          default:
            break;
        }
      }
    });

    return {
      src,
      store,
      title,
      visible,

      close,
      dataTableRef,
      tableData,
      tableColumns,

      createNew,
      exportExpenses,
    };
  },
};
</script>
