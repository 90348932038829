<template>
  <Dialog
    :header="title"
    v-model:visible="visible"
    :style="{ width: '90vw' }"
    :maximizable="true"
    :modal="true"
    :closable="false"
  >
    <div class="p-fluid p-formgrid p-grid">
      <!-- <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="id">Target Id</label>
        <InputText
          id="id"
          :disabled="src.update"
          v-model="formData.id"
          placeholder="Target Id"
          type="text"
        />
        <small class="p-error" v-if="formData.id.length == 0">
          Target Id is required.
        </small>
        <small class="p-error" v-if="src.create && isExist()">
          Target Id is used before try with diffrent id!
        </small>
      </div> -->

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="tname">Target Name</label>

        <div class="p-inputgroup">
          <Chips
            v-model="formData.name"
            id="tname"
            placeholder="Target Name"
            type="text"
            @blur="addChip('tname')"
          />
          <Button icon="pi pi-plus" @click="addChip('tname')" />
        </div>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="company">Target Company Name</label>

        <div class="p-inputgroup">
          <Chips
            v-model="formData.company"
            id="company"
            placeholder="Target Company Name"
            type="text"
            @blur="addChip('company')"
          />
          <Button icon="pi pi-plus" @click="addChip('company')" />
        </div>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="phone">Target Phone</label>
        <div class="p-inputgroup">
          <Chips
            id="phone"
            type="text"
            placeholder="Target Phone Number"
            v-model="formData.phone"
          />
          <Button icon="pi pi-plus" @click="addChip('phone')" />
        </div>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="email">Target Email</label>
        <div class="p-inputgroup">
          <Chips
            v-model="formData.email"
            id="email"
            placeholder="Target Email"
            type="text"
            @blur="addChip('email')"
          />
          <Button icon="pi pi-plus" @click="addChip('email')" />
        </div>
      </div>
      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="address">Target Address</label>

        <div class="p-inputgroup">
          <Chips
            v-model="formData.address"
            id="address"
            placeholder="Target Address"
            type="text"
            @blur="addChip('address')"
          />
          <Button icon="pi pi-plus" @click="addChip('address')" />
        </div>
      </div>
      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="website">Target Website</label>
        <div class="p-inputgroup">
          <Chips
            v-model="formData.website"
            id="website"
            placeholder="Target Website"
            type="text"
            @blur="addChip('website')"
          />
          <Button icon="pi pi-plus" @click="addChip('website')" />
        </div>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="status">Status</label>
        <Dropdown
          id="status"
          inputId="status"
          v-model="formData.status"
          :options="status"
          optionLabel="name"
          optionValue="name"
          placeholder="Select status"
        />
        <small class="p-error" v-if="formData.status.length == 0">
          Status is required.
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label>Involved in Cases</label>
        <MultiSelect
          id="involvedCases"
          v-model="involvedCases"
          :filter="true"
          :options="caseList"
          optionLabel="name"
          optionValue="id"
          placeholder="Select Cases"
          display="chip"
        />
        <!-- <div>
          <Chip
            v-for="item in caseList"
            :key="item.id"
            :label="item.id"
            class="p-shadow-2 p-m-1"
            @click="store.cases.updateDoc(item)"
          />
        </div> -->
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4" v-if="src.update">
        <label>Upload Profile Picture</label>
        <ProgressBar style="height: 0.2em" :value="progress" />

        <div class="p-inputgroup p-mt-1">
          <InputText
            id="filename"
            v-model="filename"
            placeholder="Filename"
            type="text"
          />
          <FileUpload
            :disabled="filename.length < 3 || formData.id < 3"
            mode="basic"
            :name="filename"
            :auto="true"
            :multiple="false"
            :customUpload="true"
            @uploader="uploadImages"
            accept="image/*"
            :maxFileSize="1000000"
            chooseLabel="Upload"
          />
        </div>
        <small class="p-error" v-if="filename.length < 3">
          Filename Should more then 3 latters long.
        </small>
      </div>

      <div v-if="phoneList.length > 0" class="p-field p-col-12 p-md-6 p-lg-4">
        <label>Same Phone Number</label>
        <div>
          <Chip
            v-for="item in phoneList"
            :key="item.id"
            :label="item.id"
            class="p-shadow-2 p-m-1"
            @click="store.targets.updateDoc(item)"
          />
        </div>
      </div>
      <div v-if="emailList.length > 0" class="p-field p-col-12 p-md-6 p-lg-4">
        <label>Same Email Id</label>
        <div>
          <Chip
            v-for="item in emailList"
            :key="item.id"
            :label="item.id"
            class="p-shadow-2 p-m-1"
            @click="store.targets.updateDoc(item)"
          />
        </div>
      </div>
      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label>Created</label>
        <div>
          <div>
            <Chip :label="store.date.toDateTime(formData.time)" />
          </div>
        </div>
      </div>
      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label>Last Updated</label>
        <div>
          <Chip :label="store.date.toDateTime(formData.updated)" />
        </div>
      </div>
    </div>

    <Galleria
      :value="images"
      :numVisible="7"
      containerStyle="max-width: 100%"
      :circular="true"
      :fullScreen="true"
      :showItemNavigators="true"
      :showThumbnails="true"
      v-model:visible="showImages"
    >
      <template #item="slotProps">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-6">
            <Button
              label="Delete"
              :style="{ width: 'fit-content' }"
              icon="pi pi-trash"
              class="p-button-danger p-mx-2"
              @click="daleteImg(slotProps.item)"
            />
          </div>
          <div class="p-field p-col-12">
            <img
              :src="slotProps.item.url"
              :alt="slotProps.item.alt"
              style="width: 90vw; height: 80vh; object-fit: scale-down"
            />
          </div>
        </div>
      </template>
      <template #thumbnail="slotProps">
        <img
          :src="slotProps.item.url"
          :alt="slotProps.item.alt"
          style="width: 5vw; height: 5vh; object-fit: cover"
        />
      </template>
    </Galleria>

    <template #footer>
      <Button
        v-if="src.update"
        label="Tree"
        icon="pi pi-sitemap"
        @click="src.showTree = true"
        class="p-button-success"
      />
      <Button
        v-if="src.update"
        label="Link Target"
        icon="pi pi-user"
        @click="newTarget"
        class="p-button-warning"
      />

      <Button
        v-if="src.update"
        label="Profile"
        icon="pi pi-user"
        @click="exportIt"
        class="p-button-info"
      />
      <Button
        v-if="src.update"
        label="Images"
        icon="pi pi-images"
        @click="displayImages"
        class="p-button"
      />
      <Button
        v-if="src.create"
        label="Create"
        icon="pi pi-check"
        class="p-button-success"
        @click="create"
      />
      <Button
        v-if="src.update"
        label="Update"
        icon="pi pi-check"
        class="p-button-warning"
        @click="update"
      />
      <Button
        v-if="src.update"
        label="Delete"
        icon="pi pi-trash"
        class="p-button-danger"
        @click="dalete"
      />
      <Button
        label="Close"
        icon="pi pi-times"
        @click="close"
        class="p-button-text"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import { inject, ref, watch } from "vue";
export default {
  setup() {
    const store = inject("store");
    let src = store.targets;
    const progress = ref(0);
    const images = ref();
    const showImages = ref(false);
    const formData = ref({});
    const filename = ref("");
    const involvedCases = ref([]);
    const caseList = ref([]);
    const phoneList = ref([]);
    const emailList = ref([]);
    const visible = ref(false);
    const title = ref("Create Target");
    // Objects
    const status = ref([
      { name: "Active" },
      { name: "Inactive" },
      { name: "Deleted" },
    ]);
    // Validation
    function isExist() {
      return (
        formData.value.id.length > 0 && src.byId(formData.value.id).length > 0
      );
    }
    function validate() {
      return formData.value.status.length > 0;
    }

    function getCases() {
      let tampList = [];
      store.cases.list.forEach((element) => {
        element.targets.forEach((id) => {
          if (src.data.id == id) tampList.push(element.id);
        });
      });
      return tampList;
    }
    // CRUD

    const addChip = (id) => {
      var evt = new KeyboardEvent("keydown", { keyCode: 13, which: 13 });
      document.getElementById(id).dispatchEvent(evt);
    };

    const newTarget = () => {
      store.targets.createDoc(formData.value.id);
    };

    const create = () => {
      if (validate() && !isExist()) {
        addChip('phone')
        store.table.createDoc(formData.value, "Targets");
        store.cases.targets.push(formData.value.id);
        close();
      } else alert("Somthing is wrong Refresh page and try again!");
    };
    const update = () => {
      if (validate) {
        addChip('phone')
        involvedCases.value.symDifference(getCases()).forEach((element) => {
          let data = store.cases.byId(element)[0];
          if (data) {
            data.targets.push(formData.value.id);
            store.table.updateDoc(data, "Cases");
          }
        });
        store.table.updateDoc(formData.value, "Targets");
        close();
      }
    };
    const dalete = () => {
      store.table.deleteDoc(src.data, "Targets");
      close();
    };
    const daleteImg = (img) => {
      store.deleteImage("Targets", formData.value.id, img.alt, (error) => {
        if (error == null) {
          // showImages.value = false;
          images.value = images.value.filter((image) => {
            image.alt != img.alt;
          });
        } else alert("Something Went Wrong!" + error);
      });
    };
    const close = () => {
      formData.value = src.init();
      src.close();
    };

    const exportIt = () => {
      store.exportTarget.show(src.data);
      close();
    };
    function uploadImages(event) {
      store.table.setImages(
        store.table.selectedMenu,
        src.data.id,
        event.files,
        function (snapshot) {
          progress.value =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if ((snapshot.state, "success")) {
            filename.value = "";
            setTimeout(() => {
              progress.value = 0;
            }, 1000);
            console.log("event.files", event.files.shift());
          }
        },
        filename.value
      );
    }

    async function displayImages() {
      images.value = await store.getImgs("Targets", src.data.id);
      showImages.value = true;
      if (images.value.length == 0) alert("There is no Images found.");
    }
    // Watcher
    watch([store.table, src, store.user], () => {
      formData.value = src.init();
      visible.value = src.create || src.update;
      if (visible.value) {
        phoneList.value = [];
        emailList.value = [];
        caseList.value = [];
        involvedCases.value = [];
        formData.value.link = src.data.link;
      }

      if (src.create) title.value = "Create Target";
      if (src.update) {
        title.value = "Update Target";
        involvedCases.value = getCases();
        formData.value.id = src.data.id;

        formData.value.name = src.data.name;
        formData.value.phone = src.data.phone;
        formData.value.email = src.data.email;
        formData.value.company = src.data.company;
        formData.value.address = src.data.address;
        formData.value.website = src.data.website;

        formData.value.link = src.data.link;
        formData.value.time = src.data.time;
        formData.value.status = src.data.status;
        formData.value.updated = src.data.updated;

        store.cases.list.forEach((element) => {
          caseList.value.push(element);
        });

        src.list.forEach((element) => {
          element.phone.forEach((phone) => {
            if (
              src.data.phone.length > 0 &&
              src.data.phone.includes(phone) &&
              !phoneList.value.includes(element)
            )
              phoneList.value.push(element);
          });

          element.email.forEach((email) => {
            if (
              src.data.email.length > 0 &&
              src.data.email.includes(email) &&
              emailList.value.includes(element)
            )
              emailList.value.push(element);
          });
        });
      }
    });

    return {
      src,
      store,
      title,
      status,
      visible,
      formData,
      daleteImg,

      create,
      update,
      close,
      dalete,
      isExist,
      exportIt,
      validate,

      images,
      progress,
      filename,
      caseList,
      phoneList,
      emailList,
      showImages,
      involvedCases,

      addChip,
      newTarget,
      uploadImages,
      displayImages,
    };
  },
};
</script>
