<template>
  <Dialog
    :header="title"
    v-model:visible="visible"
    :style="{ width: '90vw' }"
    :maximizable="true"
    :modal="true"
    :closable="false"
  >
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="clientId">Client Name</label>
        <Dropdown
          inputId="clientId"
          id="clientId"
          :filter="true"
          v-model="formData.clientId"
          :options="clients"
          optionLabel="name"
          optionValue="username"
          placeholder="Select client"
          @change="genId()"
        />
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="id">Case Id</label>
        <InputText
          id="id"
          :disabled="src.update"
          v-model="formData.id"
          placeholder="Case Id"
          type="text"
        />
        <small class="p-error" v-if="formData.id.length == 0">
          Case Id is required.
        </small>
        <small class="p-error" v-if="src.create && isExist()">
          Case Id is used before try with diffrent id!
        </small>
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="name">Case Name</label>
        <InputText
          v-model="formData.name"
          id="name"
          placeholder="Case Name"
          type="text"
        />
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="product">Product Name</label>
        <InputText
          v-model="formData.product"
          id="product"
          placeholder="Product Name"
          type="text"
        />
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="location">Location</label>
        <InputText
          v-model="formData.location"
          id="location"
          placeholder="Location Name"
          type="text"
        />
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="state">State</label>
        <Dropdown
          id="state"
          inputId="state"
          :filter="true"
          v-model="formData.state"
          :options="store.states"
          optionLabel="name"
          optionValue="name"
          placeholder="Select States"
        />
      </div>

      <!-- <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="targets">Targets</label>
        <MultiSelect
          id="targets"
          v-model="formData.targets"
          :filter="true"
          :options="targetList"
          :filterFields="[
            'name',
            'phone',
            'email',
            'website',
            'id',
            'address',
            'company',
          ]"
          optionLabel="name"
          optionValue="id"
          placeholder="Select Targets"
          display="chip"
        />
      </div> -->

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="managerId">Managers/Admin</label>
        <MultiSelect
          id="managerId"
          v-model="formData.managerId"
          :filter="true"
          :options="usersList"
          optionLabel="name"
          optionValue="username"
          placeholder="Select Managers"
          display="chip"
        />
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="agentsList">Agents</label>
        <MultiSelect
          id="agentsList"
          v-model="agentsList"
          :filter="true"
          :options="usersList"
          optionLabel="name"
          optionValue="username"
          placeholder="Select Agents"
          display="chip"
        />
      </div>

      <div class="p-field p-col-12 p-md-6 p-lg-4">
        <label for="status">Status</label>
        <Dropdown
          id="status"
          inputId="status"
          v-model="formData.status"
          :options="status"
          optionLabel="name"
          optionValue="name"
          placeholder="Select status"
        />
        <small class="p-error" v-if="formData.status.length == 0">
          Status is required.
        </small>
      </div>

      <div class="p-field p-col-12">
        <label for="targetsList">Targets</label>
        <div>
          <Chip
            v-for="target in formData.targets"
            :key="target"
            :label="target"
            class="p-m-1"
            @click="showTarget(target)"
          />
        </div>
      </div>
    </div>

    <div v-if="src.update" class="p-fluid p-formgrid p-grid p-mb-2">
      <div class="p-col-12 p-md-4">
        <label>Created :</label>
        <small class="p-text-normal p-m-2">
          {{ store.date.toDateTime(formData.time) }}
        </small>
      </div>
      <div class="p-col-12 p-md-4">
        <label>Last Updated :</label>
        <small class="p-text-normal p-m-2">
          {{ store.date.toDateTime(formData.updated) }}
        </small>
      </div>

      <div v-if="src.data.completed" class="p-col-12 p-md-4">
        <label>Completed :</label>
        <small class="p-text-normal p-m-2">
          {{ store.date.toDateTime(src.data.completed) }}
        </small>
      </div>
    </div>

    <DataTable
      v-if="taskList.length > 0"
      showGridlines
      dataKey="id"
      ref="dataTable"
      scrollHeight="flex"
      class="p-datatable-sm"
      selectionMode="multiple"
      responsiveLayout="scroll"
      :rows="10"
      :paginator="true"
      :scrollable="true"
      :value="taskList"
      :metaKeySelection="false"
      :rowsPerPageOptions="[5, 10, 20, 50, 100]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    >
      <template #empty> No data found. </template>
      <template #loading> Loading data. Please wait. </template>

      <Column
        :exportable="false"
        selectionMode="multiple"
        style="max-width: 37px"
        frozen
      />

      <Column
        v-for="col of store.tasks.columns"
        :field="col.field"
        :header="col.header"
        :key="col.field"
        :sortable="true"
        :scrollable="true"
        :style="col.style"
      />

      <Column header="Actions" :exportable="false" style="max-width: 100px">
        <template #body="slotProps">
          <div class="p-d-flex">
            <Button
              icon="pi pi-pencil"
              class="p-button-sm p-button-raised p-button-rounded p-mr-2"
              @click="store.tasks.updateDoc(slotProps.data, 'Tasks')"
            />

            <Button
              icon="pi pi-trash"
              class="
                p-button-sm p-button-raised p-button-rounded p-button-danger
              "
              @click="store.table.deleteDoc(slotProps.data, 'Tasks')"
            />
          </div>
        </template>
      </Column>
    </DataTable>

    <template #footer>
      <Button
        label="New Task"
        icon="pi pi-calendar"
        @click="createTask"
        class="p-button-raised p-button-secondary"
      />
      <Button
        label="New Target"
        icon="pi pi-user"
        @click="createTarget"
        class="p-button-raised p-button-secondary"
      />
      <!-- <Button
        label="New Client"
        icon="pi pi-users"
        @click="createClient"
        class="p-button-raised p-button-secondary"
      /> -->
      <Button
        v-if="src.update"
        label="Expenses"
        icon="pi pi-wallet"
        @click="opneExpenses"
        class="p-button-raised"
      />
      <Button
        v-if="src.update"
        label="Timesheet"
        icon="pi pi-clock"
        @click="opneTimesheet"
        class="p-button-raised"
      />
      <Button
        v-if="src.update"
        label="Report"
        icon="pi pi-upload"
        @click="exportReport"
        class="p-button-info"
      />
      <Button
        v-if="src.create"
        label="Create"
        icon="pi pi-check"
        class="p-button-success"
        @click="create"
      />
      <Button
        v-if="src.update"
        label="Update"
        icon="pi pi-check"
        class="p-button-warning"
        @click="update"
      />
      <Button
        v-if="src.update"
        label="Delete"
        icon="pi pi-trash"
        class="p-button-danger"
        @click="dalete"
      />
      <Button
        label="Close"
        icon="pi pi-times"
        @click="close"
        class="p-button-text"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import { inject, ref, watch } from "vue";
export default {
  setup() {
    const store = inject("store");
    let src = store.cases;
    const formData = ref({});
    const editorData = ref("");
    const visible = ref(false);
    const title = ref("Create Case");
    // Objects
    const clients = ref([]);
    const usersList = ref([]);
    const agentsList = ref([]);
    const targetList = ref([]);
    const taskList = ref([]);
    const status = ref([
      { name: "Lead" },
      { name: "Investigation Ongoing" },
      { name: "Investigation Completed" },
      { name: "Report Sent" },
      { name: "Invoicing Done" },
      // { name: "Closed" },
      { name: "Deleted" },
    ]);
    // Validation
    function isExist() {
      return (
        formData.value.id.length > 0 && src.byId(formData.value.id).length > 0
      );
    }
    function validate() {
      return formData.value.id.length > 0 && formData.value.status.length > 0;
    }

    function getAgents() {
      let agentsListTamp = [];
      store.user.list.forEach((element) => {
        if (element.cases.includes(formData.value.id)) {
          agentsListTamp.push(element.username);
        }
      });
      return agentsListTamp;
    }

    function genId() {
      let pre = clients.value.filter(
        (data) => data.username == formData.value.clientId
      )[0].code;
      let mid = "0";
      let post = 1;
      while (src.byId(pre + mid + post).length != 0) {
        if (post >= 10) mid = "";
        console.log(post++);
      }
      formData.value.id = pre + mid + post;
    }
    // CRUD
    const create = () => {
      if (validate() && !isExist()) {
        agentsList.value.symDifference(getAgents()).forEach((element) => {
          let user = store.user.byUsername(element)[0];
          if (user) {
            user.cases.push(formData.value.id);
            store.table.updateDoc(user, "Users");
          }
        });
        store.table.createDoc(formData.value, "Cases");
        close();
      }
    };

    const opneTimesheet = () => {
      store.timesheet.selected = formData.value.id;
      store.timesheet.selectedType = "cases";
      store.timesheet.show = true;
    };

    const opneExpenses = () => {
      store.expenses.selected = formData.value.id;
      store.expenses.selectedType = "cases";
      store.expenses.show = true;
    };

    const createClient = () => {
      store.client.createDoc();
    };
    const createTarget = () => {
      store.targets.createDoc();
    };
    const showTarget = (targetID) => {
      let target = store.targets.list.find((element) => element.id == targetID);
      if (target) store.targets.updateDoc(target);
      else {
        const index = formData.value.targets.indexOf(targetID);
        if (index > -1) formData.value.targets.splice(index, 1);
      }
    };
    const createTask = () => {
      store.tasks.createDoc(formData.value.id);
    };

    const update = () => {
      if (validate) {
        agentsList.value.symDifference(getAgents()).forEach((element) => {
          let user = store.user.byUsername(element)[0];
          if (user) {
            user.cases.push(formData.value.id);
            store.table.updateDoc(user, "Users");
          }
        });
        if (formData.value.status == "Invoicing Done")
          formData.value.completed = new Date().getTime();
        store.table.updateDoc(formData.value, "Cases");
        close();
      }
    };
    const dalete = () => {
      store.table.deleteDoc(src.data, "Cases");
      close();
    };
    const exportReport = () => {
      store.reports.export(src.data);
      close();
    };
    const close = () => {
      formData.value = src.init();
      src.close();
    };
    // Watcher
    watch([store.table, src, store.user], () => {
      visible.value = src.create || src.update;

      if (visible.value) {
        let clientList = [];
        let usersListTamp = [];
        let targetListTamp = [];
        let taskListTamp = [];

        formData.value.clientId = src.data.clientId;
        formData.value.managerId = src.data.managerId;
        formData.value.status = src.data.status;
        if (!formData.value.id) formData.value.id = src.data.id;
        if (!formData.value.name) formData.value.name = src.data.name;
        if (!formData.value.location)
          formData.value.location = src.data.location;
        if (!formData.value.state) formData.value.state = src.data.state;
        if (!formData.value.product) formData.value.product = src.data.product;
        if (!formData.value.time) formData.value.time = src.data.time;
        if (!formData.value.updated) formData.value.updated = src.data.updated;
        if (!formData.value.targets) formData.value.targets = [];

        formData.value.targets = formData.value.targets
          .concat(src.targets)
          .concat(src.data.targets)
          .filter((elem, pos, arr) => {
            return arr.indexOf(elem) == pos;
          });

        store.tasks.list.forEach((element) => {
          if (element.caseId == src.data.id) {
            taskListTamp.push(element);
          }
        });
        taskList.value = taskListTamp;

        store.targets.list.forEach((element) => {
          targetListTamp.push({
            id: element.id,
            name: element.name.join(" , "),
            phone: element.phone.join(" , "),
            email: element.email.join(" , "),
            address: element.address.join(" , "),
            website: element.website.join(" , "),
            company: element.company.join(" , "),
          });
        });
        targetList.value = targetListTamp;

        store.client.list.forEach((element) => {
          if (element.role == "Client") {
            clientList.push({
              name: element.name,
              username: element.username,
              code: element.code,
            });
          }
        });
        clients.value = clientList;

        store.user.list.forEach((element) => {
          usersListTamp.push({
            name: element.name,
            username: element.username,
            phone: element.phone,
          });
        });
        usersList.value = usersListTamp;

        agentsList.value = getAgents();
      }

      if (src.create) {
        title.value = "Create Case";
        if (src.data.clientId) genId();
      }
      if (src.update) {
        title.value = "Update Case";
      }
    });

    return {
      src,
      store,
      visible,
      create,
      update,
      close,
      dalete,
      title,
      formData,
      editorData,
      validate,

      status,
      clients,
      usersList,
      agentsList,
      targetList,
      isExist,
      genId,

      showTarget,
      createTarget,
      createTask,
      createClient,
      exportReport,
      opneTimesheet,
      opneExpenses,

      taskList,
    };
  },
};
</script>
