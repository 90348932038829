export function toWordDoc(
    footerText = "Location",
    id = "reportTemplate",
    filename = 'Report.doc') {
    var header = `
    <html xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office"
    xmlns:w="urn:schemas-microsoft-com:office:word"
    xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
    xmlns="http://www.w3.org/TR/REC-html40">
    <head>
            <meta http-equiv=Content-Type content="text/html; charset=utf-8">
            <title>Word Document</title>
    <style>
        v\:* {behavior:url(#default#VML);}
        o\:* {behavior:url(#default#VML);}
        w\:* {behavior:url(#default#VML);}
        .shape {behavior:url(#default#VML);}
    </style>
    <style>
        @page Section1 {
            mso-header-margin:.5in;
            mso-footer-margin:.5in;
            mso-header: h1;
            mso-footer: f1;
        }
        div.Section1 {page:Section1; }
        table#hrdftrtbl
        {
            margin:0in 0in 0in 900in;
            width:1px;
            height:1px;
            overflow:hidden;
        }
        p.MsoFooter, li.MsoFooter, div.MsoFooter
        {
            margin:0in;
            margin-bottom:.0001pt;
            mso-pagination:widow-orphan;
            tab-stops:center 3.0in right 6.0in;
            font-size:12.0pt;
        }
    </style>
    <xml>
        <w:WordDocument>
            <w:View>Print</w:View>
            <w:Zoom>100</w:Zoom>
            <w:DoNotOptimizeForBrowser />
        </w:WordDocument>
    </xml>
    </head><body>
`
    var footerStart = `
    <div class="Section1">
        <p>&nbsp;</p>
        <br/>
        <table id='hrdftrtbl' border='0' cellspacing='0' cellpadding='0'>
            <tr>
                <td>
                    <div style='mso-element:header' id=h1>
                    <span style='position:relative;z-index:-1'> 
                        <span style='mso-tab-count:2;color:red'>
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        &emsp;&emsp;&emsp;&emsp;&emsp;
                        <img src="https://firebasestorage.googleapis.com/v0/b/tbcplapp.appspot.com/o/logo.png?alt=media&token=d26c148a-e245-42c9-ba6c-52e5a4b17c65" >
                        </span>
                        </span>
                    </div>
                    &nbsp;
                </td>

                <td>
                    <div style='mso-element:footer' id=f1>
                        <span style='position:relative;z-index:-1'> 
                        <!-- FOOTER-tags -->
                            <p class=MsoFooter>
                            <span style='mso-field-code: PAGE'></span>&emsp;`;
    var footerEnd = `
    <span style='mso-tab-count:2;color:red'>CONFIDENTIAL</span> 
    </p></span></div>&nbsp;</td></tr></table></div></body></html>`;
    var docBody = document.getElementById(id).innerHTML
    docBody = docBody.replace(
        "_______PageWillBreakFromHereAndContentWillShiftToNextPage_______",
        "<pre><br clear=all style='mso-special-character:line-break;page-break-before:always'></pre>")
    docBody = docBody.replace(
        "_______PageWillBreakFromHereAndContentWillShiftToNextPage_______",
        "<pre><br clear=all style='mso-special-character:line-break;page-break-before:always'></pre>")
    var sourceHTML = header + docBody + footerStart + footerText + footerEnd;

    var source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(sourceHTML);
    var fileDownload = document.createElement("a");
    document.body.appendChild(fileDownload);
    fileDownload.href = source;
    fileDownload.download = filename;
    fileDownload.click();
    document.body.removeChild(fileDownload);
}














/*export function toWordDoc(id = "reportTemplate", filename = 'Report.doc') {
    var header = "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
        "xmlns:w='urn:schemas-microsoft-com:office:word' " +
        "xmlns='http://www.w3.org/TR/REC-html40'>" +
        "<head><meta charset='utf-8'><title>Word Document</title></head><body>";
    var footer = "</body></html>";
    var sourceHTML = header + document.getElementById(id).innerHTML + footer;
    // var sourceHTML = header + document.getElementsByClassName("ql-editor")[0].innerHTML + footer;

    var source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(sourceHTML);
    var fileDownload = document.createElement("a");
    document.body.appendChild(fileDownload);
    fileDownload.href = source;
    fileDownload.download = filename;
    fileDownload.click();
    document.body.removeChild(fileDownload);
}*/

/*
function Export2Word(element, filename = '') {
    var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    var postHtml = "</body></html>";
    var html = preHtml + document.getElementById(element).innerHTML + postHtml;

    var blob = new Blob(['\ufeff', html], {
        type: 'application/msword'
    });

    // Specify link url
    var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);



    // Create download link element
    var downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
    } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
    }

    document.body.removeChild(downloadLink);
}*/