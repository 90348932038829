import { reactive } from "vue"
import { auth, authObj, Users } from "./firebaseInit"

export const login = reactive({
    authObj,
    auth,
    getUser(username, exist, notExist, exception) {
        Users.doc(username).get().then((doc) => {
            if (doc.exists) {
                exist(doc.data())
            } else {
                notExist()
                console.log("No such document!");
            }
        }).catch((error) => {
            exception(error)
            console.log("Error getting document:", error);
        });
    }
})