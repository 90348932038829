import { reactive } from "vue"
import { Locations } from "./firebaseInit"
import store from "./index"

export const locations = reactive({
    list: [],
    data: {},
    showMap:false,

    open(username) {
        store.locations.selected = username
        store.locations.show = true
        store.locations.unSync = store.locations.sync(username)
    },

    unSync: null,
    show: false,
    selected: false,
    columns: [
        { field: 'time_', header: 'Time', style: { 'min-width': '190px', 'max-width': '200px' } },
        { field: 'address', header: 'Address', style: { 'min-width': '150px' } },
    ],
    init() {
        return {
            id: "",
            address: "",
            username: "",
            time_: "",
        }
    },

    exportIt(dataList, name) {
        store.csv.export(
            [
                { field: "time_", header: "Time" },
                { field: "id", header: "Location Id" },
                { field: "username", header: "Username" },
                { field: "Latitude", header: "Latitude" },
                { field: "Longitude", header: "Longitude" },
                { field: "address", header: "Address" },
            ],
            dataList,
            "Locations_" + name + "_" + store.date.toDateTime(new Date())
        );
    },

    close() {
        this.create = false;
        this.update = false;
        this.data = this.init()
    },

    sync(username) {
        let time = new Date(new Date().setDate(new Date().getDate()-7)).getTime();
        return Locations.where('username', '==', username).where('time','>',time).orderBy("time", "desc").onSnapshot({ includeMetadataChanges: true }, (snapshot) => {
            this.list = []
            snapshot.forEach((doc) => {
                let data = doc.data()
                data.location.forEach(element => {
                    let location = {}
                    location = element
                    location.id = doc.id
                    location.username = data.username
                    location.time_ = store.date.toDateTime(element.time)
                    this.list.push(location)
                });
            });
        }, (error) => { console.error("Locations error", error) })
    },
})