import { reactive } from "vue"
import { Clients } from "./firebaseInit"
import store from "./index"

export const client = reactive({
    list: [],
    data: {},
    create: false,
    update: false,
    show: false,
    selected: false,
    selectClient(clientUsername) {
        store.table.selection = store.client.byUsername(clientUsername)
        store.client.selected = clientUsername
    },
    showClient(clientUsername) {
        store.client.selectClient(clientUsername)
        this.show = false
        this.show = true
    },
    hideClient() {
        this.show = true
        this.show = false
    },
    splitBtn: [
        {
            label: "Activate",
            icon: "pi pi-check",
            command: () => {
                store.table.stausDoc(store.table.selection, "Active")
                store.table.selection = []
            },
        },
        {
            label: "Block",
            icon: "pi pi-ban",
            command: () => {
                store.table.stausDoc(store.table.selection, "Blocked")
                store.table.selection = []
            },
        },
    ],
    columns: [
        { field: 'username', header: 'Username' },
        { field: 'code', header: 'Code' },
        { field: 'name', header: 'Name' },
        { field: 'status', header: 'Status' },
    ],
    createDoc() {
        this.create = false
        this.create = true
        this.update = false
        this.data = this.init()
    },
    updateDoc(data) {
        this.create = false
        this.update = false
        this.update = true
        this.data = data
    },
    close() {
        this.create = false;
        this.update = false;
        this.data = this.init()
    },
    init() {
        return {
            uid: "",
            nid: "",
            code: "",
            username: "",
            name: "",
            role: "",
            status: "Active",
            time: "",
            updated: "",
        }
    },
    byUsername(username) {
        return this.list.filter(
            (data) => data.username.toLowerCase() == username.toLowerCase()
        )
    },
    sync() {
        return Clients.where('status', 'not-in', ['Deleted',]).orderBy("status").orderBy("updated", "desc").onSnapshot({ includeMetadataChanges: true }, (snapshot) => {
            this.list = []
            snapshot.forEach((doc) => {
                let data = doc.data()
                for (let index = 0; index < 1; index++) {
                    this.list.push(data)
                }
            });
        }, (error) => { console.error("Client error", error) })
    },
})