export const date = {
    obj: new Date(),
    toDateTime(millis = new Date().getTime()) {
        this.obj.setTime(millis)
        return this.obj.toLocaleString("en-IN", {
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        })
    },
    toTime(millis = new Date().getTime()) {
        this.obj.setTime(millis)
        return this.obj.toLocaleString("en-IN", {
            hour: "2-digit",
            minute: "2-digit",
        })
    },
    toDate(millis = new Date().getTime()) {
        this.obj.setTime(millis)
        return this.obj.toLocaleString("en-IN", {
            day: "2-digit",
            month: "short",
            year: "numeric",
        })
    }
}
