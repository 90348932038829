<template>
  <Login v-if="!check" />
  <div v-if="check">
    <Table />
    <User />
    <Clients />
    <Cases />
    <ListCases />
    <Tags />
    <Tasks />
    <Timesheet />
    <Target />
    <Expenses />
    <Advance />
    <Reports />
    <ExportTarget />

    <ShowClient />
    <ShowTimesheet />
    <ShowExpenses />
    <ShowAdvance />
    <ShowLocation />
    <AllLocations />
    <Reject />
    <TargetsTree />
    <PathMap />
  </div>
</template>

<script>
import { inject, onMounted, ref } from "vue";
import Table from "@/components/Table.vue";
import User from "@/components/User.vue";
import Clients from "@/components/Clients.vue";
import ShowClient from "@/components/ShowClient.vue";
import ShowTimesheet from "@/components/ShowTimesheet.vue";
import ShowExpenses from "@/components/ShowExpenses.vue";

import Cases from "@/components/Cases.vue";
import ListCases from "@/components/ListCases.vue";
import Tags from "@/components/Tags.vue";
import Tasks from "@/components/Tasks.vue";
import Timesheet from "@/components/Timesheet.vue";
import Target from "@/components/Targets.vue";
import Expenses from "@/components/Expenses.vue";
import Advance from "@/components/Advance.vue";
import Reports from "@/components/Reports.vue";
import Login from "@/components/Login.vue";
import ExportTarget from "@/components/ExportTarget.vue";
import ShowAdvance from "@/components/ShowAdvance.vue";
import ShowLocation from "@/components/ShowLocation.vue";
import Reject from "@/components/Reject.vue";
import AllLocations from "@/components/AllLocations.vue";
import TargetsTree from "@/components/TargetsTree.vue";
import PathMap from "@/components/PathMap.vue";
export default {
  name: "Home",
  components: {
    Table,
    User,
    Clients,
    ShowClient,
    ShowTimesheet,
    ShowExpenses,
    Cases,
    ListCases,
    Tags,
    Tasks,
    Timesheet,
    Target,
    Expenses,
    Advance,
    Reports,
    Login,
    ExportTarget,
    ShowAdvance,
    ShowLocation,
    Reject,
    AllLocations,
    TargetsTree,
    PathMap,
  },
  setup() {
    const store = inject("store");
    const check = ref(false);
    onMounted(() => {
      store.login.auth.onAuthStateChanged(function (user) {
        if (user && !user.isAnonymous) {
          check.value = !user.isAnonymous;
        } else check.value = false;
      });
    });
    return {
      check,
      store,
    };
  },
};
</script>
