import { reactive } from "vue"
import store from "./index"

export const exportTarget = reactive({
    visible: false,
    data: {},

    show(targetData) {
        this.data = store.targets.byId(targetData.id)[0]
        store.exportTarget.data.images = []

        store.getImgs("Targets", this.data.id,).then((images) => {
            store.exportTarget.data.images = images
            setTimeout(() => {
                store.exportTarget.visible = false
                store.exportTarget.visible = true
            }, 500);
        })


        setTimeout(() => {
            if (store.exportTarget.data.images.length == 0) {
                store.exportTarget.visible = false
                store.exportTarget.visible = true
            }
        }, 1000);

    },
    close() {
        this.visible = false;
        this.data = store.targets.init()
    },
})