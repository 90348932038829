import { reactive } from "vue"
import { Targets } from "./firebaseInit"
import store from "./index"

export const targets = reactive({
    list: [],
    data: {},

    showTree: false,

    create: false,
    update: false,

    splitBtn: [
        {
            label: "Activate",
            icon: "pi pi-check",
            command: () => {
                store.table.stausDoc(store.table.selection, "Active")
                store.table.selection = []
            },
        },
        {
            label: "Deactivate",
            icon: "pi pi-ban",
            command: () => {
                store.table.stausDoc(store.table.selection, "Inactive")
                store.table.selection = []
            },
        },
    ],
    columns: [
        { field: 'name_', header: 'Name', style: { 'min-width': '130px', } },
        { field: 'phone_', header: 'Phone', style: { 'min-width': '115px', } },
        { field: 'email_', header: 'Email', style: { 'min-width': '250px', } },
        { field: 'website_', header: 'Website', style: { 'min-width': '250px', 'overflow-x': 'auto' } },
        { field: 'company_', header: 'Company', style: { 'min-width': '200px' } },
        { field: 'products', header: 'Products', style: { 'min-width': '130px', } },
    ],
    init() {
        return {
            id: "TGT" + new Date().getTime() + Math.floor(Math.random() * 100),
            name: [],
            phone: [],
            email: [],
            address: [],
            website: [],
            company: [],
            link: "",

            time: "",
            updated: "",
            status: "Active",
        }
    },

    createDoc(link = null) {
        this.update = false
        this.create = false
        this.create = true
        this.data = this.init()
        if (link != null) this.data.link = link
    },
    updateDoc(data) {
        this.create = false
        this.update = false
        this.update = true
        this.data = data
    },
    close() {
        this.create = false;
        this.update = false;
        this.data = this.init()
    },
    byId(id) {
        return this.list.filter(
            (data) => data.id == id
        )
    },
    byPhone(phone) {
        return this.list.filter(
            (data) => data.phone == phone
        )
    },
    sync() {
        return Targets.where('status', 'not-in', ['Deleted']).orderBy("status").orderBy("updated", "desc").onSnapshot({ includeMetadataChanges: true }, (snapshot) => {
            this.list = []
            snapshot.forEach((doc) => {
                let data = doc.data()
                data.products = store.cases.byTarget(data.id).map((caseData) => { return caseData.product }).join(" , ")
                data.name_ = data.name.join(" , ")
                data.phone_ = data.phone.join(" , ")
                data.email_ = data.email.join(" , ")
                data.website_ = data.website.join(" , ")
                data.address_ = data.address.join(" , ")
                data.company_ = data.company.join(" , ")
                this.list.push(data)
            });
        }, (error) => { console.error("Cases error", error) })
    },
})